import axios from 'common/axios';
import {
    API_KEY,
    AUTH_URL,
    REFRESH_TOKEN_URL,
    SLUG_SIGN_IN,
    SLUG_SIGN_UP,
} from '../../common/axiosSettings';
import * as action from './actionTypes';
import { AUTHORIZATION } from 'common/constants';

export const openLoginScreen = () => {
    return {
        type: action.SET_MANY,
        payload: { openedLoginScreen: true },
    };
};

export const closeLoginScreen = () => {
    return {
        type: action.SET_MANY,
        payload: { openedLoginScreen: false },
    };
};

export const logout = (message) => {
    return (dispatch) => {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userEmail');
        localStorage.removeItem('userID');
        localStorage.removeItem('expirationDate');
        localStorage.removeItem('group');
        localStorage.removeItem('name');
        localStorage.removeItem('surname');
        localStorage.removeItem('openedLoginScreen');
        dispatch(logoutNext(message));
        setTimeout(() => {
            dispatch(deleteLogoutMessage());
        }, 2500);
    };
};

export const logoutNext = (message) => {
    return {
        type: action.SET_MANY,
        payload: {
            token: '',
            refreshToken: '',
            userEmail: '',
            userID: '',
            expirationDate: '',
            privileges: [],
            authorization: [],
            tasksTechnical: [],
            group: '',
            name: '',
            surname: '',
            publisherID: '',
            loading: false,
            logoutMessage: message,
            loginCompleted: false,
        },
    };
};

export const deleteLogoutMessage = () => {
    return {
        type: action.SET_MANY,
        payload: { logoutMessage: '' },
    };
};

export const setAuthTimeout = (expiresIn) => {
    return (dispatch) => {
        setTimeout(() => {
            dispatch(refreshAuth());
        }, expiresIn * 1000);
    };
};

export const authStart = () => {
    return {
        type: action.SET_MANY,
        payload: { loading: true },
    };
};

export const reportCompleted = () => {
    return {
        type: action.SET_MANY,
        payload: { reportCompleted: true },
    };
};

export const loginCompleted = () => {
    return {
        type: action.SET_MANY,
        payload: { loginCompleted: true },
    };
};

export const refreshAuth = () => {
    return (dispatch) => {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
            dispatch(logout());
        } else {
            const refreshTokenData = `grant_type=refresh_token&refresh_token=${refreshToken}`;
            axios
                .post(REFRESH_TOKEN_URL, refreshTokenData)
                .then((response) => {
                    const newResponseData = {
                        expiresIn: response?.data?.expires_in,
                        idToken: response?.data?.id_token,
                        refreshToken: response?.data?.refresh_token,
                        email: localStorage.getItem('userEmail'),
                        localId: response?.data?.user_id,
                    };
                    if (
                        !newResponseData.expiresIn ||
                        !newResponseData.idToken ||
                        !newResponseData.refreshToken ||
                        !newResponseData.email ||
                        !newResponseData.localId
                    ) {
                        dispatch(logout());
                    } else {
                        dispatch(authSuccess(newResponseData, true));
                    }
                })
                .catch((error) => {
                    dispatch(authFail(error));
                });
        }
    };
};

export const authSuccess = (responseData) => {
    return (dispatch) => {
        const expirationDate = new Date(
            new Date().getTime() + responseData.expiresIn * 1000
        );
        localStorage.setItem('token', responseData.idToken);
        localStorage.setItem('refreshToken', responseData.refreshToken);
        localStorage.setItem('userEmail', responseData.email);
        localStorage.setItem('userID', responseData.localId);
        localStorage.setItem('expirationDate', expirationDate);
        const queryParams = `?auth=${responseData.idToken}&orderBy="userID"&equalTo="${responseData.localId}"`;
        axios.get(`users.json${queryParams}`).then((res) => {
            if (!Object.keys(res.data).length) {
                dispatch(logout());
                return;
            }
            const id = Object.keys(res.data)[0];
            const queryParamsPub = `?auth=${responseData.idToken}&orderBy="publisherID"&equalTo="${res.data[id].publisherID}"`;

            const authorizationList = [];
            for (const authorization in res.data[id].authorization) {
                if (res.data[id].authorization[authorization]) {
                    authorizationList.push(authorization);
                }
            }

            localStorage.setItem('publisherID', res.data[id].publisherID);
            localStorage.setItem('name', res.data[id].name);
            localStorage.setItem('surname', res.data[id].surname);

            responseData.expirationDate = expirationDate;
            responseData.authorization = authorizationList;
            responseData.publisherID = res.data[id].publisherID;
            responseData.name = res.data[id].name;
            responseData.surname = res.data[id].surname;
            dispatch(setAuthTimeout(responseData.expiresIn));
            if (res.data[id].publisherID) {
                dispatch(fetchPrivileges(queryParamsPub, responseData));
            } else {
                dispatch(closeLoginScreenTimeout());
                dispatch(authSuccessNext(responseData));
            }
        });
    };
};

const fetchPrivileges = (queryParams, responseData) => {
    return (dispatch) => {
        axios.get(`publishers.json${queryParams}`).then((res) => {
            if (Object.keys(res.data).length < 1) {
                dispatch(closeLoginScreenTimeout());
                dispatch(authSuccessNext(responseData));
                return;
            }

            const id = Object.keys(res.data)[0];

            const privilegesList = ['publisher'];
            for (const privilege in res.data[id].privileges) {
                if (res.data[id].privileges[privilege]) {
                    privilegesList.push(privilege);
                }
            }

            const tasksTechnicalList = [];
            for (const task in res.data[id].tasksTechnical) {
                if (res.data[id].tasksTechnical[task]) {
                    tasksTechnicalList.push(task);
                }
            }
            localStorage.setItem('group', res.data[id].group);
            responseData.group = res.data[id].group;
            dispatch(closeLoginScreenTimeout());
            dispatch(authSuccessNext(responseData));
            dispatch(setPrivileges({ privilegesList, tasksTechnicalList }));
        });
    };
};

const setPrivileges = ({ privilegesList, tasksTechnicalList }) => {
    return {
        type: action.SET_PRIVILEGES,
        data: { privilegesList, tasksTechnicalList },
    };
};

export const authSuccessNext = (responseData) => {
    return {
        type: action.SET_MANY,
        payload: {
            token: responseData.idToken,
            refreshToken: responseData.refreshToken,
            userEmail: responseData.email,
            userID: responseData.localId,
            publisherID: responseData.publisherID,
            expirationDate: responseData.expirationDate,
            authorization: responseData.authorization,
            privileges: responseData?.privileges || [],
            name: responseData.name,
            surname: responseData.surname,
            group: responseData?.group,
            loading: false,
            errorMessage: '',
            regSuccessMessage: '',
        },
    };
};

export const authFail = (error) => {
    let message = error?.response?.data?.error?.message;
    let errorMessage = '';
    if (message === 'EMAIL_EXISTS') {
        errorMessage = 'Účet s týmto emailom už existuje';
    } else if (message === 'TOO_MANY_ATTEMPTS_TRY_LATER') {
        errorMessage = 'Príliš veľa pokusov, skús to neskôr';
    } else if (message === 'EMAIL_NOT_FOUND') {
        errorMessage = 'Takýto účet neexistuje. Môžeš si ho vytvoriť';
    } else if (message === 'INVALID_EMAIL') {
        errorMessage = 'Zle zadaný email';
    } else if (message === 'INVALID_PASSWORD') {
        errorMessage = 'Zadal si zlé heslo';
    } else if (message === 'USER_DISABLED') {
        errorMessage = 'Účet bol zablokovaný';
    } else if (error?.message === 'Network Error') {
        errorMessage = 'Nie si pripojený k internetu';
    } else if (error?.message === 'INVALID_REFRESH_TOKEN') {
        errorMessage = 'Nesprávny refresh token';
    } else if (error?.message === 'INVALID_LOGIN_CREDENTIALS') {
        errorMessage = 'Nesprávne prihlasovacie údaje';
    } else {
        errorMessage = `Chyba: ${message}`;
    }
    return {
        type: action.SET_MANY,
        payload: { errorMessage: errorMessage, loading: false },
    };
};

export const auth = (userData) => {
    return (dispatch) => {
        dispatch(authStart());
        const data = {
            email: userData.email,
            password: userData.password,
            returnSecureToken: true,
        };
        const URL_SLUG =
            userData.type === 'signIn' ? SLUG_SIGN_IN : SLUG_SIGN_UP;
        const url = `${AUTH_URL}${URL_SLUG}${API_KEY}`;
        axios
            .post(url, data)
            .then((response) => {
                if (userData.type === 'signIn') {
                    dispatch(authSuccess(response.data));
                }
                if (userData.type === 'signUp') {
                    dispatch(regSuccess(response.data, userData));
                }
            })
            .catch((error) => {
                dispatch(authFail(error));
            });
    };
};

export const regSuccess = (responseData, userData) => {
    return (dispatch, getState) => {
        const token = responseData.idToken;
        const userID = responseData.localId;
        const email = responseData.email;

        const data = {
            userID: userID,
            email: email,
            name: userData.name,
            surname: userData.surname,
            publisherID: '',
            authorization: '',
        };

        axios
            .get(`users/.json?auth=${token}`)
            .then((response) => {
                if (!response.data) {
                    data.authorization = {
                        [AUTHORIZATION.APPROVED]: true,
                        [AUTHORIZATION.COUNTS_SEND]: true,
                        [AUTHORIZATION.COUNTS_HISTORY]: true,
                        [AUTHORIZATION.EDIT_SCHEDULE]: true,
                        [AUTHORIZATION.EDIT_SERVICE_MEETINGS]: true,
                        [AUTHORIZATION.EDIT_TALKS]: true,
                        [AUTHORIZATION.EDIT_ANNOUNCEMENTS]: true,
                        [AUTHORIZATION.EDIT_CO_SCHEDULE]: true,
                        [AUTHORIZATION.EDIT_MEMORIAL]: true,
                        [AUTHORIZATION.LITERATURE]: true,
                        [AUTHORIZATION.TERRITORIES]: true,
                        [AUTHORIZATION.EDIT_VISITING]: true,
                        [AUTHORIZATION.EDIT_PUBLISHERS]: true,
                        [AUTHORIZATION.EDIT_USERS]: true,
                    };
                    data.publisherID = '001';
                    const publisherData = {
                        name: data.name,
                        surname: data.surname,
                        group: '1',
                        publisherID: '001',
                        ltrWT: 0,
                        ltrWL: 0,
                        ltrWB: 0,
                        ltrRR: 0,
                        ltrES: 0,
                        ltrESL: 0,
                    };
                    axios
                        .post(`publishers.json?auth=${token}`, publisherData)
                        .catch((error) => {
                            console.log(error);
                        });
                }
            })
            .then(() => {
                axios
                    .post(`users/.json?auth=${token}`, data)
                    .then(() => {
                        dispatch(afterRegSuccess());
                        dispatch(signInOpenerTimeout());
                    })
                    .catch((error) => {
                        dispatch(authFail(error));
                    });
            })
            .catch((error) => {
                console.log(error);
            });
    };
};

export const afterRegSuccess = () => {
    return {
        type: action.SET_MANY,
        payload: {
            loading: false,
            regSuccess: true,
            token: '',
            userID: '',
            userEmail: '',
            errorMessage: '',
            expirationDate: '',
            regSuccessMessage: 'Teraz sa môžeš prihlásiť',
        },
    };
};

export const switchRegSuccess = () => {
    return {
        type: action.SET_MANY,
        payload: {
            regSuccess: true,
            loading: false,
        },
    };
};

export const switchToSigningInTimeout = () => {
    return (dispatch) => {
        setTimeout(() => {
            dispatch(signInTogglerAfterReg());
            dispatch(switchRegSuccess());
        }, 700);
    };
};

export const signInToggler = () => {
    return {
        type: action.SIGN_IN_TOGGLER,
    };
};
export const signInTogglerAfterReg = () => {
    return {
        type: action.SIGN_IN_TOGGLER_AFTER_REG,
    };
};

export const signInOpenerTimeout = () => {
    return (dispatch) => {
        setTimeout(() => {
            dispatch(signInOpener());
        }, 1000);
    };
};

export const signInOpener = () => {
    return {
        type: action.SET_MANY,
        payload: { signIn: true, regSuccess: false },
    };
};

export const closeLoginScreenTimeout = () => {
    return (dispatch) => {
        setTimeout(() => {
            dispatch(closeLoginScreen());
            dispatch(loginCompleted());
        }, 200);
    };
};

export const postRegAuth = (userData) => {};
