import { CONGREGATIONS, CONGREGATION } from './config';
export const baseURLFetchVersion = CONGREGATIONS.SENICA.baseUrl;

export const API_KEY = CONGREGATIONS[CONGREGATION].apiKey;
export const baseURL = CONGREGATIONS[CONGREGATION].baseUrl;

export const AUTH_URL = 'https://identitytoolkit.googleapis.com/v1/accounts:';
export const REFRESH_TOKEN_URL = `https://securetoken.googleapis.com/v1/token?key=${API_KEY}`;

export const SLUG_PASSWORD_RESET = 'sendOobCode?key=';
export const SLUG_UPDATE = 'update?key=';
export const SLUG_SIGN_IN = 'signInWithPassword?key=';
export const SLUG_SIGN_UP = 'signUp?key=';
