import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';

import { Row, Box } from 'components/BasicComponents';
import Backdrop from './Backdrop';
import Button from './Button';
import CloseButton from './CloseButton';

export const confirmModalAsync = async ({
    props,
    alertOnly,
    title,
    question,
    submitText,
    cancelText,
    content,
}) => {
    return new Promise((resolve, reject) => {
        props.openModal(
            ConfirmModal,
            {
                onSubmit: () => {
                    props.closeModal();
                    resolve(true);
                },
                onCancel: () => {
                    props.closeModal();
                    resolve(false);
                },
                question,
                alertOnly,
                content,
                submitText,
                cancelText,
            },
            title
        );
    });
};

export const ConfirmModal = ({
    title,
    question,
    alertOnly,
    content,
    onCancel,
    cancelText,
    onSubmit,
    submitText,
}) => {
    const { t } = useTranslation();

    const handleKeyDown = useCallback(
        (e) => {
            if (e.keyCode === 27) {
                onCancel();
            }
            if (e.keyCode === 13) {
                onSubmit();
            }
        },
        [onCancel, onSubmit]
    );

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    return (
        <Backdrop isOpened isTransparent>
            <ModalStyled>
                {!alertOnly && <CloseButton onClick={onCancel} />}
                <TitleStyled>{title}</TitleStyled>
                <p>{question}</p>
                {content}
                <ButtonWrapperStyled alertOnly={alertOnly}>
                    {alertOnly ? (
                        <Button
                            text={submitText || t('Ok')}
                            onClick={onSubmit}
                            type="blue"
                            icon={'success'}
                        />
                    ) : (
                        <>
                            <Button
                                text={cancelText || t('Nie')}
                                onClick={onCancel}
                                type="red"
                                icon={'cancel'}
                                my="1rem"
                            />

                            <Button
                                text={submitText || t('Áno')}
                                onClick={onSubmit}
                                type="green"
                                icon={'success'}
                                my="1rem"
                            />
                        </>
                    )}
                </ButtonWrapperStyled>
            </ModalStyled>
        </Backdrop>
    );
};

const modal = keyframes`0% {
  transform: translateY(150vh) rotate(-90deg) scale(0);
}`;

const TitleStyled = styled(Box)`
    font-weight: bold;
    font-size: 1.7rem;
`;

const ButtonWrapperStyled = styled(Row)`
    min-width: 190px;
    padding: 10px;
    width: 90%;
    flex-wrap: wrap;
    justify-content: ${(props) =>
        props.alertOnly ? 'center' : 'space-between'};
`;

const ModalStyled = styled(Box)`
    background: #ffffff;
    width: 90%;
    max-width: 700px;
    max-height: 90vh;
    position: fixed;
    z-index: ${(props) => props.theme.zIndex.modal};
    margin: auto;
    opacity: 1;
    font-weight: 500;
    justify-content: center;
    align-items: center;
    border-radius: ${(props) => props.theme.radius.m};
    border: 1px solid #fff;
    padding: 20px;
    text-align: center;
    backdrop-filter: saturate(140%) blur(10px);
    box-shadow: 0px 0px 25px ${(props) => props.theme.colors.black2};
    transform: translateY(0);
    transition: all 0.2s ease-out;
    animation: ${modal} 0.2s ease-out;
`;
