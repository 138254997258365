import React from 'react';

import { ScheduleColumn } from './ScheduleColumn';
import { ScheduleRow } from './ScheduleRow';

const ScheduleLabelColumn = ({ data }) => {
    return (
        <ScheduleColumn isLabel>
            {data.map((row, i) => (
                <ScheduleRow
                    text={row.placeholder}
                    key={i}
                    data={row}
                    isLabel
                />
            ))}
        </ScheduleColumn>
    );
};

export default ScheduleLabelColumn;
