import React, { useState, useEffect, useCallback, useRef } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { Routes, Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';

import axios from 'common/axios';
import axiosFetchVersion from 'common/axiosFetchVersion';
import useFetch from 'hooks/useFetch';
import * as actionCreators from 'store/actions';
import {
    genericSort,
    formatName,
    createSurnameFellowsArray,
    createRowsFromSettings,
} from 'common/utils';
import { isOldWeekFrom } from 'common/utils';
import Button from 'components/UI/Button';
import { Row, Box, MessageBox } from 'components/BasicComponents';
import { confirmModalAsync } from 'components/UI/ConfirmModal';
import Loading from 'components/UI/Loading';
import { VERSION } from 'pages/myAccount/Help';

import ScheduleLabelColumn from './ScheduleLabelColumn';
import ScheduleDataColumn from './ScheduleDataColumn';
import ScheduleNewColumn from './ScheduleNewColumn';
import { processVisits } from '../../elders/Visiting/Visiting';
import Visit from '../../elders/Visiting/Visit';
import { AUTHORIZATION } from 'common/constants';

const initOpt = { name: '', surname: '' };

const initialOptions = {
    demonstration: [initOpt],
    bibleReading: [initOpt],
    prayers: [initOpt],
    reading: [initOpt],
    speeches: [initOpt],
    bibleStudyConductor: [initOpt],
    weekChairman: [initOpt],
    weekendChairman: [initOpt],
    wtStudyConductor: [initOpt],
    attendant: [initOpt],
    stageMics: [initOpt],
    mics: [initOpt],
    mix: [initOpt],
    zoom: [initOpt],
    khCleaning: [initOpt],
};

const Schedule = (props) => {
    const { t } = useTranslation();

    const [isPrinting, setIsPrinting] = useState(false);
    const [editable, setEditable] = useState('');
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState(initialOptions);
    const [columns, setColumns] = useState([]);
    const [hideOld, setHideOld] = useState(true);
    const [scheduleUpdate, setScheduleUpdate] = useState({});
    const [publishers, setPublishers] = useState([]);

    const [columnLive, setColumnLive] = useState([]);

    const token = props.auth.token;
    const name = props.auth.name;
    const surname = props.auth.surname;
    const canEdit =
        token &&
        props.auth.authorization?.includes(AUTHORIZATION.EDIT_SCHEDULE);
    const showUpdateName = !isPrinting && canEdit;

    const { data, loading: loadingVisiting, sendRequest } = useFetch();
    const isLoading = loading || loadingVisiting;

    const loadVisiting = useCallback(
        () =>
            sendRequest({
                slug: 'visiting',
                method: 'get',
                token,
                processData: processVisits,
            }),
        [sendRequest, token]
    );

    const fetchVersion = useCallback(() => {
        axiosFetchVersion
            .get('version.json')
            .then((response) => {
                return !response.data || response.data === VERSION;
            })
            .then((result) => {
                if (!result) {
                    window.location.reload(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const scheduleUpdateLoad = useCallback(() => {
        axios
            .get(`scheduleUpdate.json?auth=${token}`)
            .then((response) => {
                if (response?.data) {
                    setScheduleUpdate(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const loadSchedule = useCallback((newColumnLive) => {
        setLoading(true);
        axios
            .get(`schedule.json?auth=${token}`)
            .then((response) => {
                const newColumns = [];
                if (!response.data) {
                    setLoading(false);
                    return;
                } else {
                    const columnBlueprint = cloneDeep(newColumnLive);
                    for (let column in response.data) {
                        const savedScheduleRows = cloneDeep(
                            response.data[column]
                        );
                        const col = {
                            rows: createRowsFromSettings(
                                columnBlueprint,
                                savedScheduleRows
                            ),
                            old: isOldWeekFrom(
                                response.data[column].find(
                                    (r) => r.id === '001'
                                )?.value
                            )
                                ? true
                                : false,
                            id: column,
                        };
                        newColumns.push(col);
                    }
                    setLoading(false);
                    setColumns(newColumns);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    }, []);

    const settingsLoad = useCallback(() => {
        setLoading(true);
        fetchVersion();
        scheduleUpdateLoad();
        const newColumnLive = [];
        axios
            .get(`scheduleSettings.json?auth=${token}`)
            .then((response) => {
                if (response?.data?.scheduleRows?.length) {
                    response.data.scheduleRows
                        .filter((r) => !!r && !r?.disabled)
                        .forEach((row) => {
                            newColumnLive.push({
                                ...row,
                                value: '',
                                ...(row?.type !== 'date'
                                    ? { publisherID: '' }
                                    : {}),
                            });
                        });
                    setColumnLive(newColumnLive);
                }
                loadSchedule(newColumnLive);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [token, loadSchedule, fetchVersion, scheduleUpdateLoad]);

    const loadPublishers = useCallback(() => {
        if (canEdit) {
            setLoading(true);
            loadVisiting();
            const newPublishers = [];
            axios
                .get(`publishers.json?auth=${token}`)
                .then((response) => {
                    if (!response.data) {
                        setLoading(false);
                        return;
                    }
                    const newOptions = cloneDeep(initialOptions);
                    for (const publisher in response.data) {
                        const wantedData = {
                            name: response.data[publisher].name,
                            surname: response.data[publisher].surname,
                            suffix: response.data[publisher]?.suffix || '',
                            publisherID:
                                response.data[publisher]?.publisherID || null,
                        };
                        newPublishers.push(wantedData);
                        if (response.data[publisher].tasks) {
                            for (const task in newOptions) {
                                response.data[publisher].tasks[task] &&
                                    newOptions[task].push(wantedData);
                            }
                        }
                        if (response.data[publisher].tasksTechnical) {
                            for (const task in newOptions) {
                                response.data[publisher].tasksTechnical[task] &&
                                    newOptions[task].push(wantedData);
                            }
                        }
                    }
                    setLoading(false);
                    setOptions(newOptions);
                    setPublishers(newPublishers);
                })
                .catch((error) => {
                    setLoading(false);
                });
        }
    }, [token, canEdit, loadVisiting]);

    useEffect(() => {
        settingsLoad();
        loadPublishers();
    }, [settingsLoad, loadPublishers]);

    const changeInputHandler = (event, rowID) => {
        const newColumnLive = cloneDeep(columnLive);
        const i = newColumnLive.findIndex((row) => row.id === rowID);
        if (event.target?.type === 'select-one') {
            const wantedPublisher = publishers.find(
                (p) => p.publisherID === event.target.value
            );

            if (wantedPublisher) {
                const surnameFellows = createSurnameFellowsArray(publishers);
                const formattedName = formatName(
                    wantedPublisher,
                    surnameFellows
                );
                newColumnLive[i].publisherID = wantedPublisher?.publisherID;
                newColumnLive[i].value = formattedName;
            } else {
                newColumnLive[i].publisherID = '';
                newColumnLive[i].value = event.target.value;
            }
        } else {
            newColumnLive[i].value = event.target.value;
        }
        setColumnLive(newColumnLive);
    };

    const postHandler = () => {
        const data = columnLive.map((row) => ({
            id: row.id,
            publisherID: row.publisherID,
            value: row.value,
        }));

        axios
            .post(`schedule.json?auth=${token}`, data)
            .then(() => postUpdateDate())
            .then(() => settingsLoad());
        const newColumnLive = cloneDeep(columnLive);
        newColumnLive.forEach((row) => {
            row.value = '';
            row.publisherID = '';
        });
        setColumnLive(newColumnLive);
    };

    const postUpdateDate = () => {
        const newData = {
            date: Date.now(),
            name: `${surname} ${name.slice(0, 1)}.`,
        };
        setScheduleUpdate(newData);
        axios.patch(`scheduleUpdate.json?auth=${token}`, newData);
    };

    const beforeRemoveHandler = async (id) => {
        const confirmResult = await confirmModalAsync({
            props: props,
            title: t('Odstrániť stĺpec'),
            question: t('Natrvalo odstrániť tento stĺpec?'),
        });

        if (confirmResult) {
            removeHandler(id);
        }
    };

    const removeHandler = (id) => {
        let newColumns = columns.filter((item) => item.id !== id);
        axios
            .delete(`schedule/${id}.json?auth=${token}`)
            .then(setColumns(newColumns))
            .then(() => postUpdateDate());
    };

    const edit = (e, id) => {
        if (id !== editable) {
            return;
        }

        const newColumns = cloneDeep(columns);
        const wantedIndex = newColumns.findIndex((column) => column.id === id);
        const rIndex = newColumns[wantedIndex].rows.findIndex(
            (r) => r.id === e.target.name
        );

        if (e.target?.type === 'select-one') {
            const wantedPublisher = publishers.find(
                (p) => p.publisherID === e.target.value
            );

            const surnameFellows = createSurnameFellowsArray(publishers);
            const formattedName = formatName(wantedPublisher, surnameFellows);

            if (wantedPublisher) {
                newColumns[wantedIndex].rows[rIndex].publisherID =
                    wantedPublisher?.publisherID || null;
                newColumns[wantedIndex].rows[rIndex].value = surname
                    ? formattedName
                    : null;
            } else {
                newColumns[wantedIndex].rows[rIndex].publisherID = '';
                newColumns[wantedIndex].rows[rIndex].value = e.target.value;
            }
        } else {
            newColumns[wantedIndex].rows[rIndex].value = e.target.value;
        }
        setColumns(newColumns);
    };

    const postEditedColumn = (id) => {
        const data = columns.find((col) => col.id === id).rows;
        delete data.id;

        axios
            .delete(`schedule/${id}.json?auth=${token}`)
            .catch((error) => console.log(error));

        axios
            .post(`schedule.json?auth=${token}`, data)
            .then(() => postUpdateDate())
            .then(() => {
                settingsLoad();
                setEditable();
            })
            .catch((error) => console.log(error));
    };

    const componentRef = useRef();
    const doPrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const handlePrint = () => {
        setIsPrinting(true);
    };

    useEffect(() => {
        if (isPrinting) {
            doPrint();
            setTimeout(() => setIsPrinting(false), 100);
        }
    }, [isPrinting, doPrint]);

    const hasOldWeeks = !!columns?.filter((col) => col.old)?.length;
    const activeCols = hideOld ? columns.filter((col) => !col.old) : columns;

    const dataColumnList = [];
    genericSort(activeCols, false, 'date', 'weekValue').forEach((col) => {
        dataColumnList.push(
            <ScheduleDataColumn
                key={col.id}
                isPrinting={isPrinting}
                data={col}
                options={options}
                editData={columnLive}
                isEditable={editable === col.id}
                changeHandler={(e) => edit(e, col.id)}
                blueButton={
                    editable === col.id
                        ? () => postEditedColumn(col.id)
                        : () => setEditable(col.id)
                }
                redButton={
                    editable === col.id
                        ? () => setEditable('')
                        : () => beforeRemoveHandler(col.id)
                }
                publisherID={
                    props.auth.publisherID ||
                    localStorage.getItem('publisherID')
                }
                publishers={publishers}
            />
        );
    });

    const updateDate = new Date(scheduleUpdate.date).toLocaleString();
    const updateName = showUpdateName ? `(${scheduleUpdate.name})` : '';

    const visitingList = [];
    genericSort(data, false, 'date', 'date').forEach((visit) => {
        visitingList.push(
            <Visit
                key={visit.id}
                data={visit}
                publishers={publishers}
                isMyVisit={false}
                canEdit={false}
                editable={editable}
                edit={(e) => {}}
                redButton={() => {}}
                blueButton={() => {}}
                isInSchedule
            />
        );
    });

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <RowStyled>
                {hasOldWeeks && (
                    <Button
                        text={
                            hideOld ? t('Zobraziť staršie') : t('Skryť staršie')
                        }
                        type={hideOld ? 'blue' : 'orange'}
                        icon={'history'}
                        onClick={() => setHideOld((hideOld) => !hideOld)}
                        my="5px"
                    />
                )}
                <Button
                    text={t('Vytlačiť')}
                    type={'purple'}
                    icon={'print'}
                    onClick={handlePrint}
                    my="5px"
                />
            </RowStyled>
            {canEdit && !!data.length && (
                <Routes>
                    <Route
                        path="edit"
                        element={
                            <VisitingBoxStyled>
                                <h2>
                                    {t('Rečníci hosťujúci v iných zboroch')}
                                </h2>
                                {visitingList}
                            </VisitingBoxStyled>
                        }
                    />
                </Routes>
            )}

            {canEdit && !isLoading && !columnLive.length && (
                <Routes>
                    <Route
                        path="edit"
                        element={
                            <MessageBox error>
                                {t('Nie sú vytvorené žiadne riadky rozpisu')}
                            </MessageBox>
                        }
                    />
                </Routes>
            )}
            <Box ref={componentRef}>
                <Row>
                    {!isLoading && !!columnLive.length && (
                        <ScheduleLabelColumn data={columnLive} />
                    )}
                    {dataColumnList}
                    {!isPrinting && (
                        <Routes>
                            <Route
                                path="edit"
                                element={
                                    canEdit ? (
                                        <ScheduleNewColumn
                                            key="editColumn"
                                            change={(e, rowID) =>
                                                changeInputHandler(e, rowID)
                                            }
                                            data={columnLive}
                                            options={options}
                                            post={postHandler}
                                            publishers={publishers}
                                        />
                                    ) : (
                                        <Navigate to={'/schedule'} />
                                    )
                                }
                            />
                        </Routes>
                    )}
                </Row>
                {!!columns.length && !!scheduleUpdate.date && (
                    <LastUptadeRow>
                        {t('Posledná aktualizácia rozpisu')}:{' '}
                        {`${updateDate} ${updateName}`}
                    </LastUptadeRow>
                )}
            </Box>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        openModal: (component, componentProps, title) =>
            dispatch(
                actionCreators.openModal(component, componentProps, title)
            ),
        closeModal: () => dispatch(actionCreators.closeModal()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);

const RowStyled = styled(Row)`
    justify-content: space-between;
    flex-wrap: wrap;
`;
const LastUptadeRow = styled(Row)`
    padding: 0.5rem;
    font-size: 1.1rem;
    color: rgba(0, 0, 0, 0.4);
`;
const VisitingBoxStyled = styled(Box)`
    width: fit-content;
    padding: 1rem;
    border: 1px solid #dfdfdf;
    margin: 1rem 0;
    & h2 {
        margin: 0;
        color: #c72b46 !important;
    }
    & div:hover {
        background: transparent;
    }
`;
