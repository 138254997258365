import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';

import { mediaQueries } from 'common/theme';
import { Box, Row } from 'components/BasicComponents';
import logo from 'assets/images/elogo3.svg';

const Logo = ({ mini, cgName, handleInstall }) => {
    const { t } = useTranslation();
    return (
        <LogoWrapperStyled mini={mini}>
            <LogoTopStyled>
                <LogoStyled onClick={() => handleInstall()} />
                <LogoHeadingStyled>{t('Nástenka')}</LogoHeadingStyled>
            </LogoTopStyled>
            {!!cgName && (
                <LogoBottomStyled>{`${t('Zbor')} ${cgName}`}</LogoBottomStyled>
            )}
        </LogoWrapperStyled>
    );
};

export default Logo;

const rotate = keyframes`0% {
  transform: rotate(0deg);
}
50% {
  transform: rotate(180deg);
}
100% {
  transform: rotate(360deg);
}`;

const LogoWrapperStyled = styled(Box)`
    height: 100%;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
    color: #19213f;
    transform: ${(props) => (props.mini ? 'scale(0.8)' : 'scale(1)')};
    @media ${mediaQueries.m} {
        transform: scale(0.8);
    }
    @media ${mediaQueries.s} {
        min-width: 20px;
        padding-left: 10px;
        transform: scale(1);
    }
    @media ${mediaQueries.logoHide} {
        display: none;
    }
`;

const LogoTopStyled = styled(Row)`
    align-items: center;
    font-size: 1.9rem;
    font-weight: 800;
    @media ${mediaQueries.s} {
        height: 100%;
        width: 20px;
        font-size: 2.4rem;
    }
`;
const LogoStyled = styled(Box)`
    background: url(${logo}) no-repeat center center;
    background-size: contain;
    width: 19px;
    height: 14px;
    margin: 2px 0;
    animation: ${rotate} 0.4s linear;
    &:hover {
        animation: ${rotate} 2s infinite linear;
    }
    @media ${mediaQueries.s} {
        background: url(${logo}) no-repeat center center;
        background-size: contain;
        width: 19px;
        height: 100%;
        animation: ${rotate} 0.4s linear;
    }
`;

const LogoHeadingStyled = styled(Row)`
    @media ${mediaQueries.s} {
        display: none;
    }
`;

const LogoBottomStyled = styled(Box)`
    font-size: 1rem;
    transition: all 0.5s;
    text-transform: uppercase;
    @media ${mediaQueries.s} {
        display: none;
    }
`;
