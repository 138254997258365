import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Box } from 'components/BasicComponents';

export const VERSION = '2.10.4';

{
    /*
        --SCHEDULE
        upraviť editáciu riadkov rozpisu, pridať riadok len s prázdnou medzerou
        možnosť stĺpec v rozpise označiť ako zjazd / spoluprácu
        nový Input použitý v nastavení rozpisu
        refaktoring realInputType v scheduleRow
        opraviť transparentne pozadie inputu v schedulesettings
        neprítomných menovaných v danom týždni zobrazovať v rozbaľovacom menu červeným

        --AUTH
        kontrola tokenu pri admin post a refreshi
        refaktoring auth
        zjednodušiť kontrolu dát zvestovateľa pri vytváraní aj editácii
        skontrolovat timeout automatickeho odhlasenia, po odhlaseni a opatovnom prihlaseni asi treba zrusit povodny timeout
        pouzit constants authorization asi hotove
        skontrolovat group a suffix pri vytvarani uctu a prihlasovani
        skontrolovat nepotrebne hodnoty v localstorage

        --BUGS
        auto refresh po hodine
        opravit menu a link vnutri LinkWithRef
        zrušiť viditeľný editable stav položiek mimo /edit url
        skontrolovat vsetky povolenia zobrazenia a stahovania aj s menu polozkami
        spojit COUNTS_SEND a COUNTS_HISTORY
        skontrolovat vsetky polozky publishera ci sa nacitavaju
        oprava responzivity niektorých častí stránky
        pri prihlaseni vymazaneho používateľa sa nic nestane, mazat aj z firebase auth
        pri zmazaní poslednej starej prihlášky aj inych veci sa nenačíta prázdny stav
        po odhlásení presmerovať na rozpis zhromaždení a skryť upozornenie na neodovzdanú správu
        oznamy len pre zvestovatelov je vidiet aj pre approved v network tab
        pri uprave sa strati dany riadok ak je editovatelny a nevyhovuje filtru musi byt spraveny rovnako ako v CartPublishers

        --UI
        farby z theme bez props.theme.colors a do useContext aj pre dark theme
        dostat do selectu publishers farbu skupiny
        user a carts authbox komponent
        group report podla cgreport
        prepoužiť jednotne animácie na rotáciu, editovanej a aktívnej položky
        pouzit vsade alert component
        is authorized skontrolovat dvojaky zapis .length a .includes('approved')
        dat ikony do publishera a usera aj v rozbalovacom edite
        ikonky s povereniami v používateľoch
        zúžiť padding na mobile
        univerzálne miznúce oznámenia chýb a úspechu

        --REPORT
        upozornenie v nastaveni zboru ze mazat 30 hodinove mesiace az po spracovani spravy
        nastavenie vlastných cieľových hodín vo virtuálnom kalendári
        automaticky preniesť prebytočné minúty do ďalšieho mesiaca
        kontrolovať pred odoslaním duplicitu správy + neúplné údaje pri editácií koordinátorom skupiny
        správu kontrolovať s report DB
        radios viacero nahradnikov na spravu

        upraviť kontrolu iniciálov podľa skratky za menom
        kto aktualizoval rozpis a ine miesta poradie skratky
        kontrola iniciálov mien a skratky - počty prítomných
        možnosť meniť poradie oznamov a poznámok pri schôdzkach
        pouzit onContext menu
        pouzit drag and drop
        v nastaveniach zboru dať možnosť vypnúť rôzne moduly aplikácie
        duplicitne props v inpute
        možnosť umiestniť popis k inputom po stranách aj nad a pod ním
        refaktoring applications
        skontrolovat getDate day napriklad pri sprave ci sa ziskava pri requeste alebo nespravne pri nacitani komponentu
        routes do konstant a pouzit
        prepouzit reportsuccessmessage v modali reporte
        message error box skontrolovat + warning a success
        opraviť rolovanie obsahu
        pridat validáciu k inputu
        spravit jeden row komponent s maxwidth pre literaturu users publishers
        zjednotit poradie vnutra komponentov
        pouzit luxon
        opraviť utils dátumy UTC +2
        skontrolovat date a time hodnoty a parse
        pouzivat getDate const
        skontrolovat && zatvorky
        skontrolovat date, getDate === 1 - december
        react performance, error boundaries, useMemo, useContext


        -----FUTURE APP
        organizacna struktura zo settings kde pridam sluzobnika pre literaturu a obvody, udrzby a veduci SV
        api kluce do env suborov /na backend
        pouzit hooks na všetky fetch
        pri postEdited vymazat nepotrebne data
        hromadné mazanie položiek z databázy
        kontrolovat vsetky data pri posielani do DB kvoli refreshtoken odhlaseniu
        skontrolovat ci sa pri edit POST neprepisuju udaje vytvorene pri loade
        skontrolovat zbytocne fetche z DB
        vymazat vsetky z DB nepotrebne properties
        queries do libs
        auth prerobit na useAuth hook v useContext
        overovanie emailu pri registrácii
        súhlas s podmienkami a GDPR
        v counts vikendovy a tyzdenny den z DB, redizajn
        tlac poctov pritomnych
        zvlast screen na zabudol som heslo bez riadku na heslo
        info message box ak nie su ziadne data na zobrazenie
        možnosť vymazať účet užívateľom z auth DB + vymazanie z používateľov
        poriesit preklady
        rozosielať notifikácie s úlohami pri vytvorení nového stĺpca, novom ozname
        automatická generácia rozpisu, zobrazovať zoznam zvestovateľov zoradený podľa početnosti výskytu v minulých týždňoch
        univerzálne nahrávanie obvodov s obrazkami
        vytvoriť dokumentáciu
        testovaci ucet pre krajskeho starsieho bez id a group
        pridať nahlasovanie chýb a návrhov
        */
}

const Help = () => {
    const { t } = useTranslation();

    return (
        <HelpStyled>
            <HeadingStyled>{t('Zoznam zmien')}</HeadingStyled>
            <ChangesStyled className="past">
                {/* <Version>{`${VERSION} `} (1.11. 2024)</Version> */}
                {/* na vedenie schodzok select plus zvyraznovanie ak vediem */}
                {/* pwa monochrome icon android https://stackoverflow.com/questions/74620798/material-you-icon-on-pwa */}
                {/* <Imp>
                    nezobrazovat skrytych zvestovatelov inde nez vo
                    zvestovateloch
                </Imp>
                <Imp>
                    rozdelit stpec na tyzdennu a vikendovu cast kategórie
                    zoznamov z
                </Imp>
                <Imp>
                    DB zrychlit Inputy v schedule - prerobit inputy na useRef
                </Imp>
                <Imp>vynutit aby mal datumovy row ID 001</Imp>
                <Imp>duplikaty v newColumn prerobit na pocet v kolecku</Imp>
                <Imp>vsade loading ukoncovat vo finally</Imp>
                <Imp>
                    do vybrateho stvorca s farbou v schedulesettings dat confirm
                    icon
                </Imp> */}
                <Version>2.10.4 (29.10. 2024)</Version>
                <Imp>
                    v počtoch prítomných sa automaticky vyplní aktuálny deň a
                    dátum
                </Imp>
                <Imp>
                    v počtoch prítomných pridané meno toho, kto aktualizoval
                </Imp>
                <Version>2.10.3 (15.10. 2024)</Version>
                <Imp>
                    pri výbere tlače výdajových tabuliek v literatúre
                    automaticky nastaviť filter len na tých čo odoberajú
                    periodiká
                </Imp>
                <Imp>menšie grafické úpravy</Imp>
                <Version>2.10.2 (14.10. 2024)</Version>
                <Fix>
                    opravené načítavanie telefónnych čísel v krízovom pláne
                </Fix>
                <Imp>menšie grafické úpravy</Imp>
                <Version>2.10.1 (14.10. 2024)</Version>
                <Fix>opravená chyba v obvodoch pri chýbajúcom type obvodu</Fix>
                <Imp>
                    zoradenie zvestovateľov podľa abecedy (pozvanie na obed pri
                    prednáškach)
                </Imp>
                <Imp>grafické úpravy</Imp>
                <Version>2.10.0 (12.10. 2024)</Version>
                <New>pridaná možnosť pozvať na obed hosťujúceho rečníka</New>
                <Imp>
                    možnosť pridať telefónne číslo na starších do krízového
                    plánu
                </Imp>
                <Imp>
                    pri editácii rozpisu pridané číselné vyjadrenie počtu
                    duplicitných úloh
                </Imp>
                <Imp>zjednodušené tlačidlá a ikony</Imp>
                <Imp>grafické úpravy</Imp>
                <Version>2.9.12 (9.10. 2024)</Version>
                <Imp>grafické úpravy v Mojich obvodoch</Imp>
                <Version>2.9.11 (8.10. 2024)</Version>
                <New>nová časť "Krízový plán zboru"</New>
                <New>nový typ obvodov "Obchody"</New>
                <Version>2.9.10 (1.10. 2024)</Version>
                <Fix>
                    pri automatickom presune súčtu kreditu do poznámky pri
                    odovzdávaní správy sa nepripočítavali minúty
                </Fix>
                <Version>2.9.9 (30.9. 2024)</Version>
                <Imp>grafické úpravy</Imp>
                <Version>2.9.8 (4.8. 2024)</Version>
                <Imp>
                    pridané upozornenie pri odovzdávaní správy nečinného aby sa
                    zabránilo omylom
                </Imp>
                <Version>2.9.7 (10.7. 2024)</Version>
                <Fix>
                    opravené automatické označovanie nepretržitého pomocného
                    priekopníka v zborovej správe pri dosiahnutí zníženej kvóty,
                    teraz sa kontroluje mesiac zo správy a nie z prihlášky
                </Fix>
                <Version>2.9.6 (14.6. 2024)</Version>
                <Fix>opravené filtrovanie obvodov po termíne</Fix>
                <Version>2.9.5 (4.5. 2024)</Version>
                <Fix>opravené oprávnenia na zobrazenie skupinovej správy</Fix>
                <Imp>veľké .jpg obrázky prekonvertované do .webp formátu</Imp>
                <Version>2.9.4 (21.4. 2024)</Version>
                <New>
                    zrýchlené načítavanie aplikácie, stiahnu sa vždy len tie
                    časti stránky, ktoré užívateľ chce navštíviť
                </New>
                <Imp>položky menu na mobile predelené čiarou</Imp>
                <Imp>menšie opravy</Imp>
                <Version>2.9.3 (21.4. 2024)</Version>
                <Imp>
                    v počtoch prítomných pridané tlačidlo na zobrazenie záznamov
                    len za posledný polrok
                </Imp>
                <Imp>zvačšená výška položiek menu na mobile</Imp>
                <Version>2.9.2 (20.4. 2024)</Version>
                <Imp>
                    upravené poradie dlaždíc v priemeroch počtov prítomných
                </Imp>
                <Imp>
                    ukazovateľ načítavania v časti Môj zbor zobrazený aj počas
                    načítavania údajov o organizačnej štruktúre zboru
                </Imp>
                <Version>2.9.1 (20.4. 2024)</Version>
                <New>nový modul Môj zbor</New>
                <Imp>
                    prehľadnejšie záznamy o nečinných zvestovateľoch v správe,
                    pridané zoraďovanie týchto záznamov podľa dátumu
                </Imp>
                <Version>2.9.0 (20.4. 2024)</Version>
                <New>nový modul údržby na evidenciu menších opráv na sále</New>
                <New>
                    nový modul s evidenciou zvestovateľov schválených na stojany
                </New>
                <Imp>
                    zlepšená čitateľnosť upozornení, ikona zmenšená a zarovnaná
                    vľavo
                </Imp>
                <Imp>
                    sťahovanie dát z databázy povolené len prihláseným (z častí,
                    ktoré boli predtým verejné)
                </Imp>
                <Imp>
                    z menu odstránená časť administrácia, na editáciu sa používa
                    ikona vedľa položky menu
                </Imp>
                <Imp>prehľadnejšia kategorizácia v menu</Imp>
                <Imp>grafické úpravy v menu na mobile</Imp>
                <Imp>pridaný text k položkám v rýchlom menu na mobile</Imp>
                <Imp>skryté rýchle menu na mobile pre neprihlásených</Imp>
                <Imp>
                    editácia obvodov až po kliknutí na editáciu pri obvodoch v
                    menu
                </Imp>
                <Imp>prehľadnejšie priemery v počtoch prítomných</Imp>
                <Imp>nové filtre vo zvestovateľoch</Imp>
                <Imp>
                    pridaný filter na obvody po termíne (len pre služobníka pre
                    obvody)
                </Imp>
                <Imp>vylepšená stránka na prihlasovanie</Imp>
                <Imp>pridané upozornenie v obsahu pre neprihlásených</Imp>
                <Imp>
                    na hodnoty vo výsadách a povoleniach použité konštanty
                </Imp>
                <Imp>grafické úpravy</Imp>
                <Version>2.8.2 (14.4. 2024)</Version>
                <Imp>
                    zjednodušená aktualizácia aplikácie - číslo verzie aplikácie
                    sa sťahuje len z jednej databázy (zbor Senica)
                </Imp>
                <Version>2.8.1 (13.4. 2024)</Version>
                <New>zobrazenie % prepracovania obvodu za posledný rok</New>
                <Imp>pridané rôzne veľkosti ukazovateľa % priebehu</Imp>
                <Version>2.8.0 (13.4. 2024)</Version>
                <Fix>
                    opravená funkcia zoraďovania - položky bez hodnoty sú
                    zaraďované na koniec a už nenarúšajú zoraďovanie
                </Fix>
                <Imp>
                    vymazané nepotrebné dáta nastavení riadku z ukladaných
                    riadkov rozpisu, v nastavení rozpisu pridané upozornenie na
                    ID riadku s dátumom
                </Imp>
                <Imp>vylepšená tlač výdaju literatúry</Imp>
                <Imp>vylepšená tlač zborovej správy</Imp>
                <Imp>
                    v počtoch prítomných pridané priemery zvlášť pre zoom a
                    zvlášť pre sálu
                </Imp>
                <Imp>
                    v každom riadku v počtoch prítomných označené, či bolo viac
                    pritomných na sále alebo na zoome
                </Imp>
                <Imp>vylepšené filtre v obvodoch</Imp>
                <Imp>pridané zoraďovanie do zoznamu používateľov</Imp>
                <Imp>pridané zoraďovanie do zoznamu zvestovateľov</Imp>
                <Imp>grafické úpravy</Imp>
                <Version>2.7.13 (12.4. 2024)</Version>
                <Imp>
                    v zborovej literatúre pridaná možnosť tlačiť výdajové
                    tabuľky
                </Imp>
                <Imp>
                    pri editovaní v databáze z posielaných dát vymazané
                    nepotrebné id
                </Imp>
                <Version>2.7.12 (9.4. 2024)</Version>
                <Imp>
                    opravené nepresné zoraďovanie zvestovateľov podľa abecedy ak
                    mali rovnaké priezvisko
                </Imp>
                <Imp>možnosť pripnúť oznam</Imp>
                <Version>2.7.11 (8.4. 2024)</Version>
                <Imp>
                    automatické označenie pomocného priekopníka v zborovej
                    správe aj keď nedosiahne kvótu zo svojej prihlášky ale
                    dosiahne kvótu na pomocného priekopníka na daný mesiac
                </Imp>
                <Imp>
                    všetok obsah sa zobrazuje len prihláseným a schváleným
                    používateľom
                </Imp>
                <Version>2.7.10 (4.1. 2024)</Version>
                <Imp>možnosť označiť prihlášku ako vyhlásenú</Imp>
                <Imp>
                    v zborovej správe odlíšené počty činných v danom mesiaci a
                    za posledných 6 mesiacov
                </Imp>
                <Version>2.7.9 (13.11. 2023)</Version>
                <Fix>
                    opravený titulok v schôdzkach v prípade ak schôdzky nemajú
                    dátum
                </Fix>
                <Imp>
                    v hosťovaní prednášok pridané políčko na námet a čas
                    zhromaždenia
                </Imp>
                <Imp>grafické úpravy v časti Moja správa</Imp>
                <Version>2.7.8 (8.11. 2023)</Version>
                <Imp>grafické úpravy</Imp>
                <Imp>
                    upravené tlačivo na odovzdávanie správy podľa tlačeného
                    vzoru
                </Imp>
                <Imp>
                    odstránené návody na pripojenie sa na zhromaždenie cez Zoom
                    a na nahlasovanie na stojany
                </Imp>
                <Version>2.7.7 (7.11. 2023)</Version>
                <Fix>
                    v mesačnom rozpise opravené zvýraznenie aktuálneho týždňa,
                    predtým oneskorené podľa aktuálneho časového pásma
                </Fix>
                <Imp>
                    upravené predvolené farby v mesačnom rozpise podľa nového
                    pracovného zošita
                </Imp>
                <Version>2.7.6 (5.11. 2023)</Version>
                <Imp>
                    v skupinovej správe v odovzdanej správe bez hodín zobrazovať
                    namiesto prázdneho políčka "0"
                </Imp>
                <Version>2.7.5 (5.11. 2023)</Version>
                <Imp>
                    v zborovej správe sa odteraz červeným riadkom nerozlišuje
                    zvestovateľ, ktorý má vytvorený účet a ešte neodovzdal
                    správu ale len explicitne nečinný zvestovateľ v danom
                    mesiaci
                </Imp>
                <Version>2.7.4 (1.11. 2023)</Version>
                <New>
                    pridané ovládanie modálnych okien klávesmi esc a enter
                </New>
                <Imp>
                    odstránené zbytočné tlačidlo "Odstrániť" v zborovej
                    literatúre
                </Imp>
                <Imp>
                    zlepšené zobrazenie zborovej a skupinovej správy na mobile
                </Imp>
                <Version>2.7.3 (1.11. 2023)</Version>
                <Fix>
                    oprava zeleného zvýraznenia zvestovateľa v správe ak je
                    činný, nie len ak má hodiny
                </Fix>
                <Version>2.7.2 (1.11. 2023)</Version>
                <Imp>grafické úpravy v zborovej správe</Imp>
                <Imp>odstránené nepotrebné upozornenia pri správe</Imp>
                <Version>2.7.1 (1.11. 2023)</Version>
                <Fix>
                    oprava ošetrenia číselnej hodnoty počtu štúdii pri
                    odovzdávaní správy
                </Fix>
                <Version>2.7.0 (1.11. 2023)</Version>
                <Fix>
                    oprava automatického nastavenia tlačiva na odovzdanie správy
                    s novými údajmi
                </Fix>
                <Imp>úprava skupinovej a zborovej správy</Imp>
                <Version>2.6.19 (31.10. 2023)</Version>
                <Fix>oprava skratky koordinátora služby pri prihláškach</Fix>
                <Imp>pri odovzdávaní správy pridaný výber činný/nečinný</Imp>
                <Version>2.6.18 (17.10. 2023)</Version>
                <Imp>zo správ odstránené publikácie, videá a ON</Imp>
                <Version>2.6.17 (6.10. 2023)</Version>
                <Imp>nahradené slovo dozorca</Imp>
                <Version>2.6.16 (3.10. 2023)</Version>
                <Fix>oprava zobrazovania mena bez skratky v zozname skupín</Fix>
                <Version>2.6.15 (3.10. 2023)</Version>
                <Imp>pomocník skupiny môže zapisovať správu za skupinu</Imp>
                <Imp>
                    v zozname zvestovateľských skupín dozorcovi skupiny pridaná
                    skratka za menom
                </Imp>
                <Imp>
                    pri vytváraní zvestovateľa pridané orezávanie medzier za
                    menom
                </Imp>
                <Version>2.6.14 (1.8. 2023)</Version>
                <Imp>v poznámke k obvodu povolených viac znakov</Imp>
                <Version>2.6.13 (19.7. 2023)</Version>
                <Imp>pridaná poznámka k obvodom</Imp>
                <Imp>upravený limt na prepracovanie obvodu na 4 mesiace</Imp>
                <Version>2.6.12 (4.7. 2023)</Version>
                <Fix>
                    opravená chyba, keď sa nedali editovať stĺpce rozpisu, do
                    ktorých pribudli nové, predtým nepoužité riadky
                </Fix>
                <Version>2.6.11 (24.6. 2023)</Version>
                <Fix>
                    opravená chyba, keď sa pri vytváraní účastníka teokratickej
                    školy neukladalo jeho ID
                </Fix>
                <Imp>
                    zlepšená kontrola rovnakých inicálov, ošetrené kuriózne
                    prípady ak sú porovnávané mená rovnaké až po predposledné
                    písmeno (odstránená bodka) alebo ak nie sú mená rovnako dlhé
                    ale kratšie z porovnávaných mien je rovnaké ako časť
                    dlhšieho porovnávaného mena (napríklad Philip, Philipe -
                    obidve sa zobrazia celé a bez bodky)
                </Imp>
                <Imp>
                    porovnávanie duplicity iniciálov mien so všetkými
                    zvestovateľmi zboru, nie len s tými v danej kategórii
                    rozpisu
                </Imp>
                <Version>2.6.10 (17.6. 2023)</Version>
                <Fix>
                    ošetrená chyba odoslania záznamu do virtuálneho kalendára
                    bez ID používateľa, pridané upozornenie
                </Fix>
                <Imp>
                    pridané označovanie priekopníkov v zozname zvestovateľských
                    skupín
                </Imp>
                <Version>2.6.9 (14.6. 2023)</Version>
                <Imp>grafické úpravy rozpisu spolupráce</Imp>
                <Version>2.6.8 (8.6. 2023)</Version>
                <Imp>
                    zobrazenie skupinovej správy povolené len menovanému
                    pomocníkovi skupiny
                </Imp>
                <Version>2.6.7 (8.6. 2023)</Version>
                <Fix>
                    opravená chyba, keď sa do priemerov nezarátaval počet
                    prítomných zo zoomu
                </Fix>
                <Imp>
                    zobrazenie skupinovej správy povolené aj pomocníkovi skupiny
                </Imp>
                <Imp>
                    odstránené tlačidlá pri tlači rozpisov z časti Administrácia
                </Imp>
                <Imp>v zborovej správe pri tlači zväčšené písmo v súčtoch</Imp>
                <Imp>
                    v zborovej správe predvolene zobrazovať aj nečinných (aby si
                    tajomník všimol ak odovzdajú správu)
                </Imp>
                <Imp>zlepšené filtrovanie obdobia v počtoch prítomných</Imp>
                <Imp>
                    pridané súčty prítomných a počet zhromaždení v počtoch
                    prítomných
                </Imp>
                <Version>2.6.6 (24.5. 2023)</Version>
                <Imp>oprava poradia nadpisov v počtoch prítomných</Imp>
                <Imp>
                    grafické úpravy zobrazenia schôdzok, prednášok a oznamov na
                    mobile
                </Imp>
                <Version>2.6.5 (21.5. 2023)</Version>
                <Fix>opravená možnosť skrývania schôdzok</Fix>
                <Imp>
                    zvýrazňovanie položky "Moje obvody" v menu ak je potrebné
                    evidovať zmeny
                </Imp>
                <Imp>prehľadnejšie počty prítomných na mobile</Imp>
                <Imp>prehľadnejšie schôdzky na mobile</Imp>
                <Imp>
                    zjednodušené filtre v obvodoch, filter s metódou opracovania
                    sa zobrazuje len služobníkovi pre obvody
                </Imp>
                <Version>2.6.4 (18.5. 2023)</Version>
                <Imp>
                    optimalizácia výkonu pri prednáškach, zoradenie prebieha len
                    raz a vždy sa filtrujú už zoradené prednášky
                </Imp>
                <Imp>
                    odstránené tlačidlá zo schôdzok pri tlači z administrácie
                </Imp>
                <Imp>
                    zvýrazňovanie aktuálnych a skrývanie starých schôdzok s
                    dátumom
                </Imp>
                <Imp>
                    v počtoch prítomných pridaný filter aj na víkendové a
                    týždenné zhromaždenia
                </Imp>
                <Imp>
                    združené nastavenie "nezobrazovať v správe" a "nezobrazovať
                    v zozname skupín" nastavením "nečinný"
                </Imp>
                <Version>2.6.3 (17.5. 2023)</Version>
                <Imp>možnosť pridávať schôdzky aj s dátumom</Imp>
                <Version>2.6.2 (17.5. 2023)</Version>
                <Fix>
                    opravený indikátor načítavania v zborovej správe pri
                    neexistujúcich záznamoch
                </Fix>
                <Fix>opravené dvojité props onChange v inputoch</Fix>
                <Imp>
                    prihlášky do pomocnej môže mazať len tajomník, skrátená
                    poznámka v prihláškach
                </Imp>
                <Imp>
                    pre starších označovať nečinných červeným písmom v zozname
                    skupín, v zozname zvestovateľov a v správe
                </Imp>
                <Imp>
                    zobrazovanie nečinných starším v zvestovateľoch, skupinovej
                    správe a možnosť zobraziť ich aj v zborovej správe
                </Imp>
                <Version>2.6.1 (16.5. 2023)</Version>
                <Imp>
                    zjednotenie procesu obnovenia stránky a trvalého
                    prihlásenia, odstránenie nepouživanej funkcie
                    refreshAuthStart
                </Imp>
                <Imp>starším zobrazovať v zozname skupín aj nečinných</Imp>
                <Imp>zvestovateľom zrušená možnosť mazať svoje prihlášky</Imp>
                <Imp>
                    pridané popisy polí na vkladanie počtu prítomných (pri
                    zobrazení na mobile)
                </Imp>
                <Version>2.6.0 (16.5. 2023)</Version>
                <New>trvalé prihlásenie</New>
                <Imp>v počte prítomných pridané políčko zvlášť pre zoom</Imp>
                <Imp>
                    v zborovej správe sa pri prejdení myšou nad meno
                    zvestovateľa ukáže jeho skupina a dozorca skupiny
                </Imp>
                <Imp>vylepšené filtrovanie v zborovej literatúre</Imp>
                <Version>2.5.7 (12.5. 2023)</Version>
                <Fix>
                    oprava chyby, ktorá vznikla pri poslednej aktualizácii, keď
                    dozorca skupiny, ktorý nebol tajomník, nemohol upravovať
                    správu za svoju skupinu
                </Fix>
                <Imp>
                    ošetrené, aby v zborovej správe mohol dozorca skupiny,
                    (ktorý nie je tajomník) meniť údaje len zvestovateľom zo
                    svojej skupiny
                </Imp>
                <Imp>responzívny textový editor</Imp>
                <Imp>upravený text upozornenia v zozname prihlášok</Imp>
                <Version>2.5.6 (10.5. 2023)</Version>
                <New>
                    pridaná evidencia nečinných pre tajomníka v zborovej správe
                </New>
                <New>
                    v zborovej správe sa automaticky označujú pomocní
                    priekopníci podľa schválených prihlášok a hodín zo správy
                </New>
                <New>pridané poznámky pre starších</New>
                <Fix>
                    zobrazenie hodinového cieľa aj pomocným priekopníkom s
                    nepretržitou prihláškou
                </Fix>
                <Imp>
                    možnosť zapnúť zobrazovanie zborovej správy ktorémukoľvek
                    staršiemu
                </Imp>
                <Imp>
                    tajomníkovi pridaná možnosť editovať nepretržitosť prihlášky
                </Imp>
                <Imp>
                    možnosť mazať nepretržité prihlášky do pomocnej povolená len
                    tajomníkovi
                </Imp>
                <Imp>
                    nepretržité prihlášky sú zobrazované v samostatnom zozname
                </Imp>
                <Imp>grafické úpravy v prihláškach</Imp>
                <Imp>
                    pridané upozornenie na závislosť zobrazovania ukazovateľa
                    priebehu hodín v Mojej správe od schválenej prihlášky a
                    upozornenie na mazanie prihlášok
                </Imp>
                <Imp>pridané poradové čísla zvestovateľov v zozname skupín</Imp>
                <Imp>
                    zvýraznený stĺpec s informáciou o nepretržitej prihláške do
                    pomocnej
                </Imp>
                <Version>2.5.5 (2.5. 2023)</Version>
                <New>pridaná možnosť tlačiť zvestovateľské skupiny</New>
                <Imp>odstránené 50-hodinové prihlášky do pomocnej</Imp>
                <Imp>
                    vylepšená tlač správy, univerzálne rozloženie, pridané
                    poradové čísla a informácia o priekopníckej
                </Imp>
                <Imp>
                    pri schvaľovaní dokončeného obvodu sa nastaví budúci spôsob
                    opracovania na bežný (doteraz zostával nastavený spôsob
                    opracovania z minula)
                </Imp>
                <Version>2.5.4 (10.3. 2023)</Version>
                <New>pridaná možnosť tlačiť prihlášky</New>
                <Fix>
                    opravený popis textového políčka na poznámky v zborovej a
                    skupinovej správe
                </Fix>
                <Imp>pridané zoradenie prihlášok podľa priezviska</Imp>
                <Imp>pridaná možnosť filtrovať prihlášky podľa mesiaca</Imp>
                <Imp>pridaná informácia o počte prihlášok</Imp>
                <Imp>
                    možnosť pridať prázdny riadok prednášky len s dátumom - bez
                    námetu a rečníka
                </Imp>
                <Version>2.5.3 (23.2. 2023)</Version>
                <Fix>oprava upozornenia na neschválené prihlášky</Fix>
                <Version>2.5.2 (21.2. 2023)</Version>
                <New>
                    zvýraznenie v menu ak sú neschválené prihlášky alebo
                    užívatelia
                </New>
                <Fix>
                    opravené nepotrebné zobrazovanie lístka na odovzdanie správy
                    pri vymazaní zborovej správy tajomníkom pred skončením
                    mesiaca
                </Fix>
                <Version>2.5.1 (5.2. 2023)</Version>
                <New>
                    nový univerzálny komponent (zelené kolečko) na označenie
                    pravidelného priekopníka
                </New>
                <New>nový univerzálny tooltip komponent</New>
                <Imp>
                    pridané tooltip popisky v literatúre, zvestovateľoch,
                    používateľoch a správe
                </Imp>
                <Imp>opravená responzivita zborovej literatúry</Imp>
                <Version>2.5.0 (3.2. 2023)</Version>
                <Imp>
                    ošetrené správne nastavenie cieľových hodín, v prípade, ak
                    zvestovateľ ešte nemá odovzdanú správu za minulý mesiac
                    (nastaví sa cieľ z minulého mesiaca)
                </Imp>
                <Imp>
                    automatické vyplnenie správy podľa virtuálneho kalendára,
                    odstránené tlačidlo na prenos súčtov
                </Imp>
                <Imp>zjednodušená časť Moja správa, grafické úpravy</Imp>
                <Imp>odstránené posielanie správy na email</Imp>
                <Version>2.4.19 (2.2. 2023)</Version>
                <Fix>
                    opravené zarovnanie textu v textových poliach a ich šírka
                </Fix>
                <Fix>
                    opravený hover stav krížika na zatváranie modálneho okna na
                    safari
                </Fix>
                <Version>2.4.18 (1.2. 2023)</Version>
                <Fix>
                    opravené zobrazenie náhradníkov na odovzdávanie správy
                </Fix>
                <Fix>
                    odstránené obmedzenie maximálnej šírky stĺpca na meno v
                    používateľoch pri editovaní
                </Fix>
                <Fix>
                    opravené zobrazenie textových vstupov na meno pri editovaní
                    vo zvestovateľoch
                </Fix>
                <Imp>
                    odstránené nepotrebné posielanie zvestovateľskej skupiny v
                    správe
                </Imp>
                <Imp>
                    obmedzenie počtu znakov miesta konania pamätnej slávnosti
                </Imp>
                <Imp>
                    pridaná ikona náhradníka na odovzdávanie správy v
                    používateľoch
                </Imp>
                <Imp>možnosť nastaviť zarovnanie textu v textovom poli</Imp>
                <Imp>zjednodušenie kódu spolupráce, pamätnej a schôdzok</Imp>
                <Version>2.4.17 (30.1. 2023)</Version>
                <Fix>
                    opravené zvýrazňovanie prednášok naraz v aktuálnom aj
                    budúcom týždni (v nedeľu po 23:00)
                </Fix>
                <Fix>
                    opravený údaj prebytočných minút v kontrolnom okne pred
                    odovzdaním správy, ktorý nesprávne zobrazoval aj prebytočné
                    minúty z kreditu
                </Fix>
                <Imp>
                    zmena domény, z ktorej je posielaný súhrn odovzdanej správy
                    na email, na konkrétnu doménu zboru
                </Imp>
                <Imp>
                    možnosť odobrať skupinu zvestovateľovi a tak ho zmeniť len
                    na účastníka teokratickej školy
                </Imp>
                <Version>2.4.16 (29.1. 2023)</Version>
                <Fix>
                    opravené biele podfarbenie riadku stĺpca pri opakovaní mena
                    v stĺpci viac ako štyrikrát
                </Fix>
                <Version>2.4.15 (28.1. 2023)</Version>
                <Fix>
                    pri vytváraní alebo editácii rozpisu editor nevidel mená
                    účastníkov teokratickej školy, ktorí nie sú zvestovatelia,
                    zobrazili sa až po uložení
                </Fix>
                <Imp>
                    pridávanie ID aj účastníkom teokratickej školy, ktorí nie sú
                    zvestovatelia
                </Imp>
                <Imp>
                    v obvodoch a zborovej správe sa pri zvestovateľoch
                    kontroluje aj skupina, aby sa nezobrazili účastníci
                    teokratickej školy, ktorí síce budú mať ID (aby sa
                    zobrazovali pri editácii rozpisu) ale nebudú mať priradenú
                    skupinu
                </Imp>
                <Version>2.4.14 (27.1. 2023)</Version>
                <Imp>
                    zobrazené celé meno zvestovateľa aj so skratkou za menom v
                    obvodoch a hosťovaní
                </Imp>
                <Imp>upravená farba odkazov v oznamoch</Imp>
                <Version>2.4.13 (25.1. 2023)</Version>
                <New>
                    možnosť značiť si kredit vo virtuálnom kalendári, pri
                    presune súčtov pred odovzdaním správy sa kredit automaticky
                    vloží do poznámky
                </New>
                <Imp>
                    z filtra obvodov odstránený stav "vybraný" a "odovzdaný"
                </Imp>
                <Version>2.4.12 (24.1. 2023)</Version>
                <Imp>
                    po vymazaní celej zborovej správy tajomníkom nanovo načítať
                    stránku s vynulovanou správou
                </Imp>
                <Imp>upravený štýl tabuliek v oznamoch a schôdzkach</Imp>
                <Imp>možnosť pridávať poznámky k schôdzkam</Imp>
                <Imp>
                    v správe za skupinu ošetrené zobrazenie len zvestovateľov s
                    ID zvestovateľa (bez účastníkov teokratickej školy)
                </Imp>
                <Imp>
                    pridaná možnosť skryť nečinných v zozname skupín alebo v
                    správe
                </Imp>
                <Imp>zväčšená šírka stĺpcov v zborovej správe</Imp>
                <Version>2.4.11 (12.1. 2023)</Version>
                <Imp>
                    zrušené automatické resetovanie zoradenia obvodov pri
                    editácii alebo uložení
                </Imp>
                <Imp>
                    upravená logika v počtoch prítomných, kde boli doteraz
                    napevno definované dni zhromaždenia na štvrtok a nedeľu
                </Imp>
                <Imp>
                    možnosť zobraziť v počtoch prítomných len určité obdobie, za
                    ktoré sa aj zobrazujú priemery
                </Imp>
                <Imp>grafické úpravy v rozpise pamätnej</Imp>
                <Imp>
                    obmedzená šírka stĺpca s menom v zvestovateľoch,
                    používateľoch a literatúre, aby dlhšie meno nezasahovalo do
                    ďalších stĺpcov
                </Imp>
                <Version>2.4.10 (11.1. 2023)</Version>
                <New>pridaná možnosť tlačiť rozpis schôdzok</New>
                <Fix>
                    opravená chyba, keď sa správa zvestovateľa, ktorému bola po
                    odovzdaní správy zmenená skupina, nezobrazovala jeho novému
                    vedúcemu skupinky
                </Fix>
                <Imp>
                    upravená tlač prednášok, spolupráce a pamätnej tak, aby sa
                    tlačil aj nadpis
                </Imp>
                <Imp>
                    možnosť vymazať všetky uložené riadky rozpisu v nastaveniach
                    rozpisu
                </Imp>
                <Imp>
                    zobrazené upozornenie v prípade, ak v databáze síce sú
                    uložené stĺpce rozpisu ale ID ich riadkov nevyhovujú
                    žiadnemu ID riadku nastavenému v nastaveniach rozpisu alebo
                    ešte žiadne riadky nastavené nie sú; v tomto prípade bol
                    tiež odstránený stĺpec s popiskami riadkov a zbytočne
                    zobrazované tlačidlo upraviť stĺpec
                </Imp>
                <Version>2.4.9 (10.1. 2023)</Version>
                <Imp>
                    možnosť filtrovať v požiadavke literatúry len tých, čo
                    odoberajú SV, VP alebo PZ
                </Imp>
                <Version>2.4.8 (9.1. 2023)</Version>
                <Imp>
                    možnosť editácie už pridaných riadkov v rozpise zhromaždení
                </Imp>
                <Imp>možnosť dočasne skryť riadok v nastaveniach rozpisu</Imp>
                <Version>2.4.7 (8.1. 2023)</Version>
                <Fix>opravy menších chýb</Fix>
                <Imp>upravené pridávanie nových obvodov</Imp>
                <Imp>
                    v prípade zhody priezvisiek aj mien 2 zvestovateľov a
                    rozlišovania len na základe skratky za menom sa už zbytočne
                    nezobrazujú celé mená ale boli skrátené len na začiatočné
                    písmeno
                </Imp>
                <Imp>
                    pridaná informácia o potrebe každý mesiac ručne mazať
                    odovzdanú zborovú správu po jej spracovaní tajomníkom
                </Imp>
                <Imp>
                    skratka s menom zvestovateľa pridaná aj v správe za skupinu,
                    v zborovej správe a v zozname všetkých prihlášok
                </Imp>
                <Version>2.4.6 (7.1. 2023)</Version>
                <Fix>
                    po odoslaní novej prihlášky do pomocnej priekopníckej služby
                    za zvestovateľa zostával výber hodín označený a v niektorých
                    prípadoch nebolo možné odoslať ďalšiu prihlášku s rovnakým
                    počtom hodín
                </Fix>
                <Imp>
                    pridaná možnosť slúžiť neptretržite v prihláške do pomocnej
                    priekopníckej služby
                </Imp>
                <Imp>
                    upravené kvóty pre priekopníkov platné od 1. marca 2023
                </Imp>
                <Imp>upravená dĺžka poznámky k schôdzke na viac znakov</Imp>
                <Imp>
                    v prihláške za zvestovateľa nastavená predvolená hodnota bez
                    mena zvestovateľa
                </Imp>
                <Version>2.4.5 (6.1. 2023)</Version>
                <Fix>
                    opravená chyba, keď sa náhradníkovi na odovzdávanie správy
                    nastavila zvestovateľská skupina zastupovaných zvestovateľov
                    napevno a pri zmene ich skupiny sa správa stále odosielala s
                    číslom pôvodnej skupiny, tým pádom sa ani nezobrazovala v
                    správe za skupinu novému vedúcemu skupiny ale pôvodnému
                </Fix>
                <Fix>
                    opravené správanie filtra vo zvestovateľoch, keď sa
                    zvestovateľ pri zmene skupiny na nevyhovujúcu nastavenému
                    filtru stratil zo zobrazenia
                </Fix>
                <Version>2.4.4 (2.1. 2023)</Version>
                <Fix>
                    opravené zobrazovanie chybového hlásenia na nesprávnom
                    mieste (pri editácii zvestovateľa)
                </Fix>
                <Fix>
                    opravené zbytočné kontrolovanie ID zvestovateľa pri editácii
                    účastníka teokratickej školy
                </Fix>
                <Fix>
                    opravené strácanie kurzora z textového políčka pri editácii
                    v literatúre a používateľoch
                </Fix>
                <Fix>
                    opravené správanie filtra v obvodoch, keď sa pri editácii
                    obvodu a zmene jeho stavu na nevyhovujúci nastavenému filtru
                    stratil zo zobrazenia
                </Fix>
                <Imp>
                    skratka za menom v zvestovateľských skupinách, literatúre,
                    mojej literatúre a prihláškach
                </Imp>
                <Imp>
                    vylepšené vytváranie vlastných riadkov v rozpise zhromaždení
                </Imp>
                <Imp>
                    proces registrácia nového účtu zjednodušený na 1 krok,
                    požiadavka literatúry odstránená z procesu registrácie
                </Imp>
                <Imp>
                    požiadavka literatúry presunutá v DB z používateľov do
                    zvestovateľov
                </Imp>
                <Imp>možnosť tlačiť literatúru</Imp>
                <Imp>filtrovanie literatúry podľa skupín</Imp>
                <Imp>
                    pri vytváraní nového zboru inicializácia prvého užívateľa
                    ako administrátora s plnými právami
                </Imp>
                <Imp>
                    odstránené farebné zvýrazňovanie dní pri tlači spolupráce
                </Imp>
                <Version>2.4.3 (19.12. 2022)</Version>
                <Imp>
                    pre editorov mesačného rozpisu viditeľné aj meno toho, kto
                    naposledy aktualizoval rozpis
                </Imp>
                <Imp>
                    zobrazovať menovaných hosťujúcich s prednáškou v iných
                    zboroch aj nad mesačným rozpisom pri editácii
                </Imp>
                <Version>2.4.2 (28.11. 2022)</Version>
                <Fix>opravené zvýraznenie aktívnej položky v menu</Fix>
                <Fix>opravy menších chýb</Fix>
                <Imp>zoradenie schôdzok aj podľa času, nie len podľa dňa</Imp>
                <Version>2.4.1 (27.11. 2022)</Version>
                <New>
                    pridaná možnosť tlačiť rozpis spolupráce a pamätnej
                    slávnosti
                </New>
                <New>pridaná možnosť pridať skratku k menu zvestovateľa</New>
                <Imp>
                    pri upravovaní obvodu na voľný skryť tlačidlo na výber
                    obvodu
                </Imp>
                <Imp>možnosť zavrieť upozornenie na neodovzdanú správu</Imp>
                <Version>2.4.0 (23.11. 2022)</Version>
                <New>
                    pridaná možnosť tlačiť rozpisy zhromaždení a prednášok
                </New>
                <Imp>
                    pridaný dátum poslednej aktualizácie rozpisu zhromaždení
                </Imp>
                <Imp>odstránenie časti návod zoom host</Imp>
                <Imp>upravená animácia zvýraznenej úlohy</Imp>
                <Imp>
                    upravený text tlačidiel na zobrazenie starších rozpisov
                </Imp>
                <Version>2.3.17 (17.11. 2022)</Version>
                <Imp>
                    zobrazenie informácie ak používateľ nemá vyzvihnutý žiadny
                    obvod
                </Imp>
                <Imp>
                    tlačidlo na zobrazenie predošlých dní/týžňov len ak také
                    existujú
                </Imp>
                <Imp>
                    vo vyzdvihnutom obvode zobrazovať informáciu, že na ňom môže
                    začať pracovať
                </Imp>
                <Version>2.3.16 (30.9. 2022)</Version>
                <Imp>pridané pokyny k odovzdávaniu správy</Imp>
                <Version>2.3.15 (6.9. 2022)</Version>
                <Imp>
                    kontrola zhodných iniciálov mien zvestovateľov s rovnakým
                    priezviskom a následná automatická generácia rozlíšiteľných
                    skrátených mien (pri vytváraní aj editácii rozpisu)
                </Imp>
                <Imp>tlačidlo späť nahor pri príliš vysokom obsahu</Imp>
                <Version>2.3.14 (1.9. 2022)</Version>
                <Fix>
                    opravené zelené pozadie na schválenej prihláške v časti Moje
                    prihlášky
                </Fix>
                <Version>2.3.13 (17.8. 2022)</Version>
                <Imp>zmena názvov stavu obvodu</Imp>
                <Version>2.3.12 (12.8. 2022)</Version>
                <Fix>
                    oprava funkcie na filtrovanie predošlých kalendárnych dní,
                    ktorá predtým nebrala do úvahy časové pásmo
                </Fix>
                <Imp>
                    pridaný popis v používateľoch pri pridávaní náhradníka na
                    odovzdávanie správy
                </Imp>
                <Version>2.3.11 (8.8. 2022)</Version>
                <Fix>
                    zvýraznenie obvodu po termíne červeným pozadím len
                    pridelenému zvestovateľovi a služobníkovi pre obvody
                </Fix>
                <Imp>grafické úpravy</Imp>
                <Version>2.3.10 (5.8. 2022)</Version>
                <Fix>opravená funkcia tlačidiel v obvodoch</Fix>
                <Fix>
                    opravené oprávnenie v obvodoch na odovzdávanie len svojich
                    vlastných obvodov
                </Fix>
                <Version>2.3.9 (4.8. 2022)</Version>
                <Imp>
                    tlačidlo na schvaľovanie odovzdaných a vybraných obvodov
                    služobníkom pre obvody
                </Imp>
                <Imp>vylepšený dizajn Mojich obvodov</Imp>
                <Version>2.3.8 (2.8. 2022)</Version>
                <Imp>upozornenie na nové obvody</Imp>
                <Version>2.3.7 (2.8. 2022)</Version>
                <Fix>
                    opravené zobrazovanie upozornenia na neodovzdanú správu,
                    ktoré sa nezobrazovalo, ak užívateľ už mal nejakú odovzdanú
                    správu ale staršiu ako z minulého mesiaca
                </Fix>
                <Imp>grafické úpravy</Imp>
                <Imp>popisky pri obvodoch na mobile</Imp>
                <Version>2.3.6 (2.8. 2022)</Version>
                <Imp>zlúčené Obvody s Mojimi obvodami</Imp>
                <Version>2.3.5 (21.7. 2022)</Version>
                <Imp>
                    oddelená časť s obvodmi, ktoré vyžadujú zapísať zmeny v
                    evidencii
                </Imp>
                <Version>2.3.4 (20.7. 2022)</Version>
                <Imp>
                    nové stavy obvodov na upozornenie služobníka pre obvody -
                    nedávno pridelený a nedávno odovzdaný
                </Imp>
                <Version>2.3.3 (6.7. 2022)</Version>
                <Imp>
                    možnosť editácie hosťujúcich rečníkov a pridané oprávnenie
                    editovať len svoje vlastné záznamy
                </Imp>
                <Version>2.3.2 (5.7. 2022)</Version>
                <Imp>
                    automatické nastavenie cieľových hodín v správe pre
                    pravidelných priekopníkov
                </Imp>
                <Version>2.3.1 (4.7. 2022)</Version>
                <Imp>
                    možnosť nastaviť dátum prepracovania obvodu pri jeho
                    odovzdávaní
                </Imp>
                <Imp>
                    kruhový indikátor pokroku vo virtuálnom kalendári (pre
                    pomocných priekopníkov)
                </Imp>
                <Imp>zobrazenie dátumu odovzdania obvodu</Imp>
                <Imp>zvýrazňovanie obvodov po termíne</Imp>
                <Version>2.3.0 (1.7. 2022)</Version>
                <New>
                    evidencia obvodov, možnosť pre zvestovateľov vybrať si a
                    odovzdať obvod, filtrovanie obvodov
                </New>
                <Imp>upozornenie na zmeny po aktualizácii aplikácie</Imp>
                <Imp>univerzálna funkcia na formátovanie dátumov</Imp>
                <Version>2.2.20</Version>
                <Imp>prihláška použitá ako univerzálny komponent</Imp>
                <Imp>opravené zobrazenie počtu prítomných na mobile</Imp>
                <Imp>zlepšená prehľadnosť časti Moja správa</Imp>
                <Version>2.2.19</Version>
                <Imp>nový univerzálny Input komponent použitý v správe</Imp>
                <Imp>
                    redizajn a zjednodušenie počtov prítomných, univerzálne
                    priemery
                </Imp>
                <Version>2.2.18</Version>
                <Imp>
                    opravené anglické názvy kategórií predsedajúceho vo štvrtok,
                    predsedajúceho v nedeľu, vedúceho skupiny a dozorcu služby
                </Imp>
                <Version>2.2.17</Version>
                <Fix>
                    opravená chyba, keď sa celkovo 3 zvestovatelia v časti
                    "Správa za skupinu" a "Literatúra za skupinu" farebne
                    neodlišovali ako takí, ktorí majú účet na ENASE
                </Fix>
                <Version>2.2.16</Version>
                <New>nový informačný typ modálneho okna</New>
                <Fix>
                    opravená chyba, keď bolo možné pridať ako náhradníka na
                    odovzdávanie správy samého seba
                </Fix>
                <Imp>
                    v zozname prihlášok a náhradníkov na odovzdávanie správy sú
                    vyfiltrovaní len zvestovatelia (nie všetci účastníci
                    teokratickej školy)
                </Imp>
                <Imp>
                    zlepšené nastavenie náhradníkov na odovzdávanie správy,
                    oprava mazania náhradníkov
                </Imp>
                <Imp>ošetrená maximálna výška modálneho okna</Imp>
                <Version>2.2.15</Version>
                <Fix>
                    načítavanie správy podľa ID zvestovateľa nie podľa ID
                    používateľa. Vďaka tomu sa správa zapísaná vedúcim skupiny
                    alebo tajomníkom zobrazí aj zvestovateľovi s účtom v časti
                    Moja správa a ani sa mu nebude nesprávne zobrazovať
                    upozornenie na neodovzdanú správu
                </Fix>
                <Imp>
                    zjednodušenie kódu a zjednotenie zborovej a skupinovej
                    správy, dizajnové vylepšenia
                </Imp>
                <Imp>opravené poradie prednášok v rýchlom menu na mobile</Imp>
                <Imp>konštanta na znížený počet hodín v nastaveniach zboru</Imp>
                <Version>2.2.14</Version>
                <Imp>zlepšenie kódu v používateľoch a zvestovateľoch</Imp>
                <Imp>
                    nový univerzálny Input komponent v používateľoch,
                    zvestovateľoch, prihláškach, nastaveniach účtu, údržbe,
                    zborovej literatúre a v požiadavke literatúry
                </Imp>
                <Version>2.2.13</Version>
                <Imp>nastavená maximálna šírka upozornení a tlačidiel</Imp>
                <Imp>zjednodušené parametre univerzálneho tlačidla</Imp>
                <Imp>
                    zlepšené farby a pozadia tlačidiel, zväčšené písmo a veľkosť
                    tlačidla
                </Imp>
                <Version>2.2.12</Version>
                <Imp>
                    indikácia načítavania pri schvalovaní prihlášky služobným
                    výborom
                </Imp>
                <Imp>
                    prihlášky používajú ID zvestovateľa namiesto ID používateľa.
                    Vďaka tomu sa zvestovateľom s účtom zobrazí v časti Moje
                    prihlášky aj prihláška, ktorú za nich podal starší
                </Imp>
                <Imp>zlepšenie kódu prihlášok</Imp>
                <Imp>
                    rozbaľovací zoznam so zvestovateľmi namiesto textového poľa
                    pri pridávaní prihlášok staršími
                </Imp>
                <Imp>
                    univerzálny komponent Button používa jednotné parametre z
                    theme
                </Imp>
                <Imp>
                    pridaná možosť skryť text tlačidiel pre úzke obrazovky
                </Imp>
                <Imp>zmenšené riadkovanie prednášok na mobile</Imp>
                <Imp>
                    odstránená nepotrebná medzera okolo políčok v rozpise
                    zhromaždení
                </Imp>
                <Version>2.2.11</Version>
                <Imp>nový univerzálny komponent s číslom skupiny</Imp>
                <Imp>pridané tlačidlo prednášok do rýchleho menu</Imp>
                <Imp>zlepšená prístupnosť aplikácie</Imp>
                <Imp>nový typ inputu malých rozmerov</Imp>
                <Imp>
                    nové univerzálne upozornenia použité pri všetkých hláseniach
                </Imp>
                <Version>2.2.10</Version>
                <Imp>
                    nové univerzálne upozornenia použité pri chybových
                    hláseniach
                </Imp>
                <Imp>menšie opravy, opravená ponuka inštalácie aplikácie</Imp>
                <Imp>
                    zvýšené zabezpečenie - autorizácia, výsady a technické úlohy
                    sa už neukladajú do lokálnej pamäte prehliadača
                </Imp>
                <Version>2.2.9</Version>
                <Imp>použitý z-index a border-radius z theme</Imp>
                <Imp>použité univerzálne mediaQueries</Imp>
                <Imp>redizajn obvodov</Imp>
                <Version>2.2.8</Version>
                <Imp>redizajn schôdzok</Imp>
                <Imp>zlepšená štruktúra súborov</Imp>
                <Imp>vytvorené nové konštanty</Imp>
                <Imp>vytvorená téma so štýlmi</Imp>
                <Version>2.2.7</Version>
                <Imp>
                    nastavená maximálna šírka oznamov, spolupráce a schôdzok
                </Imp>
                <Imp>nový Input komponent použitý v nastavení zboru</Imp>
                <Imp>redizajn prednášok</Imp>
                <Imp>aktualizácia na React Router 6.3</Imp>
                <Imp>dizajnové vylepšenia, upravené oznamy</Imp>
                <Version>2.2.6</Version>
                <Fix>opravené zabezpečenie administrovaných častí</Fix>
                <Imp>
                    presmerovanie neexistujúcich url na domovskú obrazovku
                </Imp>
                <Imp>
                    pri obnovení stránky presmerovanie na pôvodnú url aj pri
                    zabezpečených url
                </Imp>
                <Imp>veľkosť písma v relatívnej jednotke v násobkoch 1px</Imp>
                <Version>2.2.5</Version>
                <Imp>
                    pridaný animovaný krížik na zatváranie modálnych okien
                </Imp>
                <Imp>nové sezónne pozadia na prihlasovacej stránke</Imp>
                <Imp>
                    zvýšené zabezpečenie - autorizácia, výsady a technické úlohy
                    sú v array nie v objekte
                </Imp>
                <Imp>pri obnovení stránky presmerovať na pôvodnú url</Imp>
                <Imp>nový Input komponent použitý v oznamoch</Imp>
                <Version>2.2.4</Version>
                <Imp>zväčšovanie ikony v menu na desktope</Imp>
                <Imp>
                    nový Input komponent použitý v hosťovaní, prednáškach a v
                    počtoch prítomných
                </Imp>
                <Imp>
                    CSS premenné v Buttone, nové pozadia, zväčšovanie ikony
                </Imp>
                <Imp>axios premenné, kľúče databázy</Imp>
                <Imp>zlepšená štruktúra priečinkov, absolútne importy</Imp>
                <Imp>refaktoring Inputu (farby duplikácií)</Imp>
                <Version>2.2.3</Version>
                <Fix>
                    opravená chyba pri výbere prázdnej položky z rozbaľovacieho
                    zoznamu pri vytváraní stĺpca v rozpise zhromaždení
                </Fix>
                <Imp>refaktoring schôdzok + použitý Input komponent</Imp>
                <Imp>nový Input komponent</Imp>
                <Imp>upravený dizajn oznamov</Imp>
                <Imp>zjednotené poradie importov</Imp>
                <Version>2.2.2</Version>
                <Fix>opravené zbytočné vykresľovanie hlavičky</Fix>
                <Imp>zlepšenie kódu backdropu</Imp>
                <Imp>
                    obmedzená maximálna šírka potvrdzovacieho modálneho okna
                </Imp>
                <Imp>nový univerzálny fetch hook</Imp>
                <Imp>Row a Box komponenty, dizajnové vylepšenia</Imp>
                <Imp>
                    upozornenie na neodovzdanú správu ako samostatný komponent
                </Imp>
                <Version>2.2.1 a staršie</Version>
                <Fix>
                    opravená chyba, keď pri editácii záznamu tajomníkom v
                    zborovej správe sa tomuto záznamu nepriradilo číslo
                    zvestovateľskej skupiny a tým pádom sa nezobrazoval v časti
                    Správa za skupinu
                </Fix>
                <Imp>skrývanie hlavičky pri rolovaní nadol</Imp>
                <Imp>
                    pridaná možnosť vymazať záznam správy dozorcom
                    skupiny/tajomníkom
                </Imp>
                <Imp>možnosť pridať poznámku k záznamu počtu prítomných</Imp>
                <Imp>možnosť dočasne skryť schôdzku v rozpise</Imp>
                <Imp>
                    možnosť editovať ID zvestovateľa a kontrola jeho duplicity
                </Imp>
                <Imp>
                    ošetrené vytvorenie 2 zvestovateľov s rovnakým menom a
                    priezviskom
                </Imp>
                <New>
                    možnosť upravovať názvy úloh a ich poradie v mesačnom
                    rozpise
                </New>
                <Imp>pridaný víkendový dátum v rozpise zhromaždení</Imp>
                <Imp>zlepšenie kódu zhromaždení</Imp>
                <Imp>odstrániť options pri edite zhromaždení</Imp>
                <Fix>opravené zoraďovanie podľa dátumu na Safari</Fix>
                <Imp>refaktoring správy a Button</Imp>
                <Imp>
                    refaktoring editable, destructuring props, grafické úpravy
                </Imp>
                <Imp>pridané % pokroku do virtuálneho kalendára</Imp>
                <Imp>
                    kartička správy s univerzálnym textom namiesto obrázka
                </Imp>
                <Imp>
                    možnosť spravovať náhradníkov na odovzdávanie správy,
                    možnosť odovzdávať správu aj za viacerých zvestovateľov
                </Imp>
                <Imp>zlúčenie poverení služobného výboru, refaktoring</Imp>
                <Fix>oprava virtuálneho kalendára</Fix>
                <Fix>oprava zjednodušených oprávnení</Fix>
                <Fix>
                    oprava chýbajúcej možnosti pre starších pridať 30 hod
                    prihlášku za zvestovateľa
                </Fix>
                <Imp>refaktoring správy a virtuálneho kalendára</Imp>
                <Imp>odstránené REDUX_DEVTOOLS</Imp>
                <Imp>custom NavLink v header a menu</Imp>
                <Fix>pri odhlásení skryť upozornenie na neodovzdanú správu</Fix>
                <Imp>
                    vynútené obnovenie stránky pri nezhode s DB verziou appky
                </Imp>
                <Imp>presmerovanie na https</Imp>
                <Imp>možnosť upraviť oznam</Imp>
                <Imp>číslo piesne v rozpise prednášok</Imp>
                <Imp>
                    história počtu prítomných pre usporiadateľov, možnosť
                    editácie
                </Imp>
                <Imp>správna pozícia súčtov pri tlači zborovej správy</Imp>
                <Imp>refaktoring filter funkcií</Imp>
                <Imp>
                    vylepšené odovzdávanie správy za niekoho z inej skupiny
                </Imp>
                <Imp>aktualizované návody na pripájanie na ZOOM</Imp>
                <Imp>
                    dovoliť dozorcovi skupiny vkladať správu len od 1. do 20.
                    dňa v mesiaci
                </Imp>
                <Imp>
                    možnosť pridať účastníka teokratickej školy, ktorý nie je
                    zvestovateľ
                </Imp>
                <Fix>
                    možnosť schváliť viac užívateľov s prázdnym ID zvestovateľa
                </Fix>
                <Imp>skrývať staré dni spolupráce</Imp>
                <Imp>
                    univerzálna kontrola a odlišovanie zvestovateľov s rovnakými
                    priezviskami a iniciálmi v mesačnom rozpise
                </Imp>
                <Imp>refaktoring rozpisu zhromaždení</Imp>
                <Imp>
                    vylepšené upozornenie na duplicity pri vytváraní a editovaní
                    úloh
                </Imp>
                <Imp>
                    zvýraznený čas nasledujúcej udalosti v aktuálnom dni
                    spolupráce
                </Imp>
                <Imp>
                    počet prebytočných minút zo správy posielať do emailu a
                    modálneho okna
                </Imp>
                <Imp>refaktoring zvestovateľských skupín</Imp>
                <Imp>zvýraznený aktuálny deň spolupráce a staré vyblednuté</Imp>
                <New>nové univerzálne modálne okná</New>
                <Fix>
                    opravené nepotrebné sťahovanie stavu odovzdania správy
                </Fix>
                <Imp>väčšia medzera okolo tlačidla "zabudol som heslo"</Imp>
                <Imp>oprava zmenšovania loga na mobile</Imp>
                <Imp>zväčšená aktívna ikona rýchleho menu na mobile</Imp>
                <Fix>opravené zvýrazňovanie prednášok naraz v 2 týždňoch</Fix>
                <Imp>
                    pomocným priekopníkom sa zobrazuje pokrok aj po skončení
                    mesiaca
                </Imp>
                <Imp>refaktoring virtuálneho kalendára</Imp>
                <Imp>zlepšená tlač správy pre tajomníka</Imp>
                <Imp>zlepšená validácia emailových adries</Imp>
                <Imp>nové logo a manifest ikony</Imp>
                <Imp>efekt rozmazania pri rozbalení menu a v hlavičke</Imp>
                <Imp>backdrop v portali</Imp>
                <Imp>pridaný loading do chýbajúcich sekcií</Imp>
                <Imp>refaktoring Reduxu</Imp>
                <Fix>
                    zvýšené zabezpečenie, pri obnovení stránky stiahnuť
                    poverenia z databázy
                </Fix>
                <Imp>
                    zvýraznenie úloh aj pre neprihlásených - podľa posledného
                    prihláseného
                </Imp>
                <Fix>nevyžadovať e-mail pri zmene hesla</Fix>
                <Fix>hromadenie duplicít v rozpise zhromaždení</Fix>
                <Imp>v zborovej a skupinovej správe ako prvé priezvisko</Imp>
                <Imp>
                    zoraďovanie zvestovateľov v správe podľa priezviska nie ID
                </Imp>
                <Imp>zoraďovanie v údržbe od najnovšieho dátumu</Imp>
                <Imp>vylepšené triedenie podľa abecedy s diakritikou</Imp>
                <Imp>univerzálne triedenie</Imp>
                <Fix>
                    6 najnovším účtom pri prihlásení nepriradilo číslo skupiny a
                    ich správa sa nezobrazovala dozorcovi skupiny
                </Fix>
                <New>evidencia obvodov</New>
                <Imp>oddeliť požiadavku literatúry od nastavení účtu</Imp>
                <Imp>zmena pozadia podľa ročného obdobia</Imp>
                <Imp>neaktívny stav tlačidiel</Imp>
                <Imp>zvýraznenie aktuálnej schôdzky</Imp>
                <New>rozpis údržby sály</New>
                <Imp>ikonky s povereniami v zozname zvestovateľov</Imp>
                <Imp>ikonka pri registrovaných zvestovateľoch</Imp>
                <New>zoznam zmien</New>
                <Imp>skrývanie predošlých týždňov zhromaždení a prednášok</Imp>
                <Imp>zvýraznenie aktuálneho týždňa v rozpisoch</Imp>
                <Imp>galéria obvodov</Imp>
                <Imp>zlepšené počty prítomných, filter na každý deň</Imp>
                <Imp>počty prítomných s číslom dňa</Imp>
                <New>jazykové mutácie</New>
                <New>rozpis spolupráce s KD</New>
                <New>rozpis Pamätnej slávnosti</New>
                <Imp>ikony v menu a na tlačidlách</Imp>
                <New>možnosť zmeniť heslo a email</New>
                <New>možnosť obnoviť zabudnuté heslo</New>
                <Imp>úprava dátumu a dňa v počtoch prítomných</Imp>
                <Imp>
                    automatické zoraďovanie rozpisu zhromaždení podľa dátumu
                </Imp>
                <Imp>možnosť editovať dátum v rozpise zhromaždení</Imp>
                <Imp>
                    zvýraznenie úloh prihláseného uživateľa v rozpise
                    zhromaždení
                </Imp>
                <Imp>nové jednotné tlačidlá</Imp>
                <Imp>automatické zoraďovanie schôdzok podľa dní</Imp>
                <Imp>možnosť editovať schôdzky a prednášky</Imp>
                <Imp>textový editor na vytváranie oznamov</Imp>
                <Imp>
                    nastavenie viditeľnosti oznamu len pre prihlásených
                    zvestovateľov
                </Imp>
                <Imp>
                    zoraďovanie zvestovateľov a používateľov podľa abecedy
                </Imp>
                <Imp>načítavanie správy len z minulého mesiaca</Imp>
                <Imp>možnosť tlačiť správu</Imp>
                <Imp>zaokrúhľovanie súčtov v správe na 2 desatinné miesta</Imp>
                <Imp>možnosť úpravy správy pre tajomníka</Imp>
                <New>nastavenie názvu zboru a 30-hodinových mesiacov</New>
                <New>evidencia literatúry pre všetkých zvestovateľov</New>
                <New>pridaná požiadavka denných textov</New>
                <Imp>
                    upozornenie na duplicitné ID pri vytváraní zvestovateľa
                </Imp>
                <Imp>
                    upozornenie na viaceré úlohy pre jedného zvestovateľa pri
                    vytváraní rozpisu zhromaždení
                </Imp>
                <Imp>automatické načítavanie počtu zvestovateľských skupín</Imp>
                <New>rýchle menu na mobile</New>
                <Imp>zúženie hlavičky</Imp>
                <Imp>zoraďovanie zvestovateľských skupín</Imp>
                <Imp>zoraďovanie prednášok podľa dátumu</Imp>
                <Imp>zoraďovanie počtu prítomných podľa dátumu</Imp>
            </ChangesStyled>
        </HelpStyled>
    );
};

export default Help;

const HeadingStyled = styled.h2`
    margin-bottom: 0;
`;

const HelpStyled = styled(Box)`
    font-size: 1.4rem;
`;

const ChangesStyled = styled.ul`
    padding-left: 0px;
    margin: 0;
    text-align: left;
    list-style-type: none;
    & li {
        padding: 2px;
    }
    & li::before {
        margin-right: 7px;
        font-family: 'Font Awesome 5 Free';
        font-weight: 600;
    }
`;

const Fix = styled.li`
    color: #393939;
    &::before {
        content: '\f7d9';
        color: rgb(204, 134, 115);
    }
`;
const Imp = styled.li`
    color: #393939;
    &::before {
        content: '\f102';
        color: rgb(130, 194, 240);
    }
`;

const New = styled.li`
    color: #393939;
    &::before {
        content: '\f0fe';
        color: rgb(99, 200, 141);
    }
`;

const Version = styled.li`
    color: #3e3e3e;
    margin-top: 15px;
    padding: 0;
    font-weight: 700;
    font-size: 1.5rem;
`;
