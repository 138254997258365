import React from 'react';
import styled from 'styled-components';

import { mediaQueries } from 'common/theme';
import hamburger from 'assets/images/hamburger.svg';

const HamburgerIcon = ({ onClick, inSideDrawer }) => (
    <HamburgerIconStyled
        onClick={onClick}
        inSideDrawer={inSideDrawer}
        aria-label="Menu"
    />
);

export default HamburgerIcon;

const HamburgerIconStyled = styled.button`
    display: none;

    @media ${mediaQueries.m} {
        background: url(${hamburger}) no-repeat center center;
        background-size: 100%;
        min-width: 22px;
        width: 22px;
        min-height: 22px;
        height: 22px;
        cursor: pointer;
        display: block;
        border: 0;
        outline: none;
        margin: ${(props) => (props.inSideDrawer ? '1.5rem' : '')};
    }
`;
