import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Row } from 'components/BasicComponents';
import { VERSION } from 'pages/myAccount/Help';

const VersionRow = () => {
    const resetScroll = () => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    };
    return (
        <VersionRowStyled aria-disabled="true">
            <Link id="version-row" to="/myAccount/help" onClick={resetScroll}>
                {VERSION}
            </Link>
        </VersionRowStyled>
    );
};

export default VersionRow;

const VersionRowStyled = styled(Row)`
    justify-content: flex-end;

    & a#version-row {
        padding: 0 0.5rem;
        font-size: 1.1rem;
        font-weight: 700;
        color: ${(props) => props.theme.colors.black4};
        width: auto;
        &:focus {
            color: ${(props) => props.theme.colors.black4};
            background: transparent;
        }
        &:hover,
        &:active {
            color: #000000;
            background: transparent;
        }
        &::after {
            background: transparent;
        }
    }
`;
