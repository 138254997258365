import React from 'react';
import styled from 'styled-components';

import { mediaQueries } from 'common/theme';
import { Box, Row } from 'components/BasicComponents';
import Menu from '../Menu';
import Backdrop from './Backdrop';
import HamburgerIcon from './HamburgerIcon';
import VersionRow from 'components/VersionRow';

const SideDrawer = ({ onClick, openedSideDrawer }) => (
    <WrapperStyled>
        <Backdrop onClick={onClick} isOpened={openedSideDrawer} />
        <SideDrawerStyled onClick={onClick} isOpened={openedSideDrawer}>
            <Menu inSideDrawer />
            <RowStyled>
                <HamburgerIcon inSideDrawer />
                <VersionRow />
            </RowStyled>
        </SideDrawerStyled>
    </WrapperStyled>
);

export default SideDrawer;

const SideDrawerStyled = styled(Box)`
    width: 50%;
    min-width: 300px;
    height: 100%;
    max-height: 100%;
    background: #fff;
    position: fixed;
    bottom: 0;
    z-index: ${(props) => props.theme.zIndex.sideDrawer};
    padding-top: 5px;
    padding-right: 10px;
    transition: transform 0.1s ease-in-out;
    transform: translateY(${(props) => (props.isOpened ? '0%' : '100%')});
    @media ${mediaQueries.s} {
        width: 100%;
        height: 80%;
        max-height: 500px;
    }
`;

const WrapperStyled = styled.div`
    @media ${mediaQueries.mMin} {
        display: none;
    }
`;
const RowStyled = styled(Row)`
    width: 100%;
    align-items: center;
    justify-content: space-between;
`;
