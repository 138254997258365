export const Icons = {
    announcement: 'fas fa-bullhorn',
    arrowup: 'fas fa-arrow-up',
    application: 'fas fa-file-upload',
    arrowdown: 'fas fa-arrow-down',
    arrowright: 'fas fa-arrow-right',
    administration: 'fas fa-edit',
    arrowleft: 'fas fa-arrow-left',

    bag: 'fas fa-briefcase',
    bagtime: 'fas fa-business-time',
    ban: 'fas fa-ban',
    book: 'fas fa-book',
    building: 'fas fa-building',
    buildingAlt: 'far fa-building',

    calendar: 'fas fa-calendar-alt',
    calendarday: 'fas fa-calendar-day',
    calendarweek: 'fas fa-calendar-week',
    calendarcheck: 'far fa-calendar-check',
    calculator: 'fas fa-calculator',
    cancel: 'fas fa-times',
    clipboard: 'fas fa-clipboard-check',
    clock: 'far fa-clock',
    cooperation: 'fas fa-street-view',

    delete: 'far fa-trash-alt',
    door: 'fas fa-door-open',
    download: 'fas fa-cloud-download-alt',

    edit: 'fas fa-pencil-alt',
    emergency: 'fas fa-medkit',
    envelope: 'fas fa-envelope',
    envelopeList: 'fas fa-envelope-open-text',

    food: 'fas fa-utensils',
    female: 'fas fa-male',
    filter: 'fas fa-filter',

    globe: 'fas fa-globe-americas',
    group: 'fas fa-users',

    hamburger: 'fas fa-bars',
    hand: 'fas fa-hand-holding',
    help: 'far fa-question-circle',
    helmet: 'fas fa-hard-hat',
    history: 'fas fa-history',
    hide: 'far fa-eye-slash',
    home: 'fas fa-home',
    house: 'fas fa-house-user',
    hourglass: 'fas fa-hourglass-half',
    hourglassend: 'fas fa-hourglass-end',

    info: 'fas fa-info',
    infocircle: 'fas fa-info-circle',

    login: 'fas fa-sign-in-alt',
    logout: 'fas fa-sign-out-alt',

    map: 'fas fa-map-marked-alt',
    mapmarker: 'fas fa-map-marker-alt',
    male: 'fas fa-male',
    medical: 'fas fa-notes-medical',
    minus: 'fas fa-minus',

    organizer: 'fas fa-user-shield',

    peoplearrows: 'fas fa-people-arrows',
    phone: 'fas fa-phone',
    pin: 'fas fa-map-pin',
    print: 'fas fa-print',
    publishers: 'far fa-id-badge',
    plus: 'fas fa-plus',

    random: 'fas fa-random',
    reset: 'fas fa-sync',

    sad: 'far fa-frown',
    save: 'far fa-save',
    send: 'far fa-paper-plane',
    select: 'fas fa-hand-pointer',
    settings: 'fas fa-tools',
    show: 'far fa-eye',
    smile: 'far fa-smile',
    sort: 'fas fa-sort',
    sortAZ: 'fas fa-sort-alpha-down',
    sortZA: 'fas fa-sort-alpha-down-alt',
    speaker: 'fas fa-user-tie',
    star: 'fas fa-star',
    success: 'fas fa-check',
    successCircle: 'fas fa-check-circle',

    travel: 'fas fa-suitcase-rolling',
    tractor: 'fas fa-tractor',
    truck: 'fas fa-truck-moving',

    unlock: 'fas fa-unlock-alt',
    user: 'fas fa-user',
    users: 'fas fa-user-friends',
    usernew: 'fas fa-user-plus',
    useredit: 'fas fa-user-edit',
    userdelete: 'fas fa-user-times',
    usersettings: 'fas fa-user-cog',

    warning: 'fas fa-exclamation-triangle',
    wine: 'fas fa-wine-glass-alt',
};

export const TasksIcons = {
    demonstration: 'fas fa-people-arrows',
    bibleReading: 'fas fa-book-reader',
    prayers: 'fas fa-praying-hands',
    reading: 'fas fa-book-open',
    speeches: 'fas fa-user-tie',
    bibleStudyConductor: 'fas fa-book',
    weekChairman: 'fas fa-street-view',
    weekendChairman: 'fas fa-user-tie',
    wtStudyConductor: 'fas fa-hand-peace',
};
export const TasksTechnicalIcons = {
    attendant: 'fas fa-user-shield',
    stageMics: 'fas fa-microphone-alt',
    mics: 'fas fa-microphone',
    mix: 'fas fa-headphones',
    zoom: 'fas fa-photo-video',
    khCleaning: 'fas fa-broom',
};
export const PrivilegesIcons = {
    pioneer: 'fas fa-door-open',
    ministryServant: 'fas fa-people-carry',
    groupAssistant: 'fas fa-users',
    elder: 'fas fa-user-tie',
    groupOverseer: 'fas fa-users',
    serviceOverseer: 'far fa-map',
    secretary: 'fas fa-file-alt',
    coordinator: 'fas fa-user-cog',
    carts: 'fas fa-suitcase-rolling',
};
