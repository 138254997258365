const ALL_CONGREGATIONS = {
    TEST: 'TEST',
    SENICA: 'SENICA',
    HAVIROV_ZAPAD: 'HAVIROV_ZAPAD',
    SKALICA: 'SKALICA',
};

export const CONGREGATION = ALL_CONGREGATIONS.SENICA;

export const CONGREGATIONS = {
    TEST: {
        apiKey: 'AIzaSyBeIFf2ZgGzX7CHOUks96gVqqX7Z2gRpj8',
        baseUrl:
            'https://test-enase-default-rtdb.europe-west1.firebasedatabase.app/',
        domain: 'test.enase.eu',
        googleTerritory:
            'https://drive.google.com/open?id=15teXC2jqbus86YDp-bBASDSY20N5KPOe&usp=sharing',
        globalTerritory: false,
        schedule: true,
        serviceMeetings: true,
        talks: true,
        announcements: true,
        cooperation: true,
        memorial: true,
        groups: true,
        carts: true,
        report: true,
        territories: true,
        literature: true,
        applications: true,
        counts: true,
    },
    SENICA: {
        apiKey: 'AIzaSyCiYnjE7u9RA8agLTiSMVB3DoVDJcMo7no',
        baseUrl: 'https://enase-72c16-default-rtdb.firebaseio.com/',
        domain: 'enase.eu',
        googleTerritory:
            'https://drive.google.com/open?id=15teXC2jqbus86YDp-bBASDSY20N5KPOe&usp=sharing',
        globalTerritory: true,
        schedule: true,
        serviceMeetings: true,
        talks: true,
        announcements: true,
        cooperation: true,
        memorial: true,
        groups: true,
        carts: true,
        report: true,
        territories: true,
        literature: true,
        applications: true,
        counts: true,
    },
    HAVIROV_ZAPAD: {
        apiKey: 'AIzaSyCvuB1Dx7MgRs-jAkkPP1lJVvRIe9EUYWw',
        baseUrl: 'https://ha-enase-default-rtdb.firebaseio.com/',
        domain: 'haza.enase.eu',
        googleTerritory: false,
        globalTerritory: true,
        schedule: true,
        serviceMeetings: true,
        talks: true,
        announcements: true,
        cooperation: true,
        memorial: true,
        groups: true,
        carts: true,
        report: true,
        territories: true,
        literature: true,
        applications: true,
        counts: true,
    },
    SKALICA: {
        apiKey: 'AIzaSyC4v5BB3iEPjKHw4H-ALWQA0V5CSR4_ZPk',
        baseUrl:
            'https://skalica-10f61-default-rtdb.europe-west1.firebasedatabase.app/',
        domain: 'si.enase.eu',
        googleTerritory: false,
        globalTerritory: true,
        schedule: true,
        serviceMeetings: true,
        talks: true,
        announcements: true,
        cooperation: true,
        memorial: true,
        groups: true,
        carts: true,
        report: true,
        territories: true,
        literature: true,
        applications: true,
        counts: true,
    },
};
