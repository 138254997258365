import React from 'react';
import styled from 'styled-components';

import { Box } from '../BasicComponents';
import closeIcon from '../../assets/images/hamclose.svg';

const CloseButton = ({ onClick, top, right }) => (
    <CloseButtonStyled onClick={onClick} top={top} right={right}>
        <img src={closeIcon} />
    </CloseButtonStyled>
);

export default CloseButton;

const CloseButtonStyled = styled(Box)`
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: ${(props) => props.top || '1rem'};
    right: ${(props) => props.right || '1rem'};
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s ease-out;

    & img {
        width: 1.5rem;
        height: 1.5rem;
    }
    &:hover {
        background: #f3f3f3;
    }
`;
