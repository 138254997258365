import React, { lazy, Suspense, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Navigate, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { mediaQueries } from 'common/theme';
import { PRIVILEGES, AUTHORIZATION } from 'common/constants';
import { Box, MessageBox } from 'components/BasicComponents';
import { Icons } from 'common/Icons';

import Loading from 'components/UI/Loading';
import Schedule from 'pages/administration/Schedule/Schedule';
import Help from 'pages/myAccount/Help';

const ServiceMeetings = lazy(() =>
    import('pages/administration/ServiceMeetings/ServiceMeetings')
);

const Talks = lazy(() => import('pages/administration/Talks/Talks'));
const Announcements = lazy(() =>
    import('pages/administration/Announcements/Announcements')
);
const Cooperation = lazy(() =>
    import('pages/administration/Cooperation/Cooperation')
);
const Memorial = lazy(() => import('pages/administration/Memorial/Memorial'));

const Report = lazy(() => import('pages/myAccount/Report/Report'));
const Applications = lazy(() =>
    import('pages/myAccount/Applications/Applications')
);
const MyTerritories = lazy(() =>
    import('pages/myAccount/MyTerritories/MyTerritories')
);
const MyCongregation = lazy(() =>
    import('pages/myAccount/MyCongregation/MyCongregation')
);
const MyLiterature = lazy(() =>
    import('pages/myAccount/MyLiterature/MyLiterature')
);
const Settings = lazy(() => import('pages/myAccount/Settings/Settings'));
const Tutorials = lazy(() => import('pages/myAccount/Tutorials/Tutorials'));
const Emergency = lazy(() => import('pages/myAccount/Emergency/Emergency'));

const Counts = lazy(() => import('pages/elders/Counts/Counts'));
const CartPublishers = lazy(() =>
    import('pages/elders/CartPublishers/CartPublishers')
);
const Notes = lazy(() => import('pages/elders/Notes/Notes'));
const ApplicationsAll = lazy(() =>
    import('pages/elders/Applications/ApplicationsAll')
);
const Visiting = lazy(() => import('pages/elders/Visiting/Visiting'));
const GroupReport = lazy(() => import('pages/elders/GroupReport/GroupReport'));
const CGReport = lazy(() => import('pages/elders/CGReport/CGReport'));
const Literature = lazy(() => import('pages/elders/Literature/Literature'));
const MaintenanceReports = lazy(() =>
    import('pages/elders/MaintenanceReports/MaintenanceReports')
);
const Groups = lazy(() => import('pages/elders/Groups/Groups'));
const Publishers = lazy(() => import('pages/elders/Publishers/Publishers'));
const Users = lazy(() => import('pages/elders/Users/Users'));
const CGSettings = lazy(() => import('pages/elders/CGSettings/CGSettings'));
const ScheduleSettings = lazy(() =>
    import('pages/elders/ScheduleSettings/ScheduleSettings')
);

const ContentRight = ({ auth }) => {
    const { t } = useTranslation();
    const [topButtonVisible, setTopButtonVisible] = useState(false);

    const isLoggedIn = !!auth?.token;

    const isApproved = !!(
        isLoggedIn && auth.authorization.includes(AUTHORIZATION.APPROVED)
    );
    const isPublisher = !!(
        isApproved && auth?.privileges.includes(PRIVILEGES.PUBLISHER)
    );
    const isMinistryServant = !!(
        isPublisher && auth.privileges.includes(PRIVILEGES.MINISTRY_SERVANT)
    );

    const isElder = !!(
        isPublisher && auth.privileges.includes(PRIVILEGES.ELDER)
    );
    const isGroupOverseer = auth.privileges.includes(PRIVILEGES.GROUP_OVERSEER);
    const isServiceOverseer = !!(
        isPublisher && auth.privileges.includes(PRIVILEGES.SERVICE_OVERSEER)
    );
    const isSecretary = !!(
        isPublisher && auth.privileges.includes(PRIVILEGES.SECRETARY)
    );
    const isCoordinator = !!(
        isPublisher && auth.privileges.includes(PRIVILEGES.COORDINATOR)
    );
    const isCommittee = !!(isServiceOverseer || isSecretary || isCoordinator);

    const countsAllowed =
        isPublisher &&
        (auth.authorization.includes(AUTHORIZATION.COUNTS_HISTORY) ||
            auth.authorization.includes(AUTHORIZATION.COUNTS_SEND));

    const isGroupAssistant = !!auth.privileges.includes(
        PRIVILEGES.GROUP_ASSISTANT
    );

    const groupReportAllowed =
        (isElder && isGroupOverseer) ||
        ((isElder || isMinistryServant) && isGroupAssistant);
    const applicationsAllowed = (isElder && isGroupOverseer) || isCommittee;
    const visitingAllowed = isMinistryServant || isElder || isCommittee;
    const literatureAllowed =
        isLoggedIn &&
        (auth.authorization.includes(AUTHORIZATION.LITERATURE) ||
            isGroupOverseer);
    const cgReportAllowed =
        isSecretary ||
        (isElder && auth.authorization.includes(AUTHORIZATION.VIEW_REPORT));
    const publishersAllowed =
        isPublisher &&
        (auth.authorization.includes(AUTHORIZATION.EDIT_PUBLISHERS) ||
            isCoordinator);
    const usersAllowed =
        isPublisher &&
        (auth.authorization.includes(AUTHORIZATION.EDIT_USERS) ||
            isCoordinator);
    const cgSettingsAllowed =
        isPublisher &&
        (auth.authorization.includes(AUTHORIZATION.EDIT_USERS) || isCommittee);
    const scheduleSettingsAllowed =
        isPublisher &&
        (auth.authorization.includes(AUTHORIZATION.EDIT_USERS) || isCommittee);

    const resetScroll = () => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    };

    const clickToTopHandler = useCallback(() => {
        const currentPosition = window.scrollY;
        if (currentPosition > 1000) {
            setTopButtonVisible(true);
        } else {
            setTopButtonVisible(false);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', clickToTopHandler, {
            passive: false,
        });
        return () => {
            window.removeEventListener('scroll', clickToTopHandler, {
                passive: false,
            });
        };
    }, [clickToTopHandler]);

    const renderElement = ({ path, element, show }) => {
        if (!show) return null;
        return (
            <Route
                path={path}
                element={<Suspense fallback={<Loading />}>{element}</Suspense>}
            />
        );
    };

    return (
        <ContentStyled>
            <TopButtonStyled visible={topButtonVisible} onClick={resetScroll}>
                <i className={Icons['arrowup']} />
            </TopButtonStyled>
            <Routes>
                <Route path="/" element={<Navigate to="/schedule" />} />
                <Route path="/myAccount/help/*" element={<Help />} />
                <Route
                    path="/schedule/*"
                    element={
                        isApproved ? (
                            <Schedule />
                        ) : (
                            <WarningStyled>
                                <MessageBox warning>
                                    {t('Obsah len pre prihlásených')}
                                </MessageBox>
                            </WarningStyled>
                        )
                    }
                />

                {renderElement({
                    path: '/serviceMeetings/*',
                    element: <ServiceMeetings />,
                    show: isApproved,
                })}

                {renderElement({
                    path: '/talks/*',
                    element: <Talks />,
                    show: isApproved,
                })}

                {renderElement({
                    path: '/announcements/*',
                    element: <Announcements />,
                    show: isApproved,
                })}

                {renderElement({
                    path: '/cooperation/*',
                    element: <Cooperation />,
                    show: isApproved,
                })}

                {renderElement({
                    path: '/memorial/*',
                    element: <Memorial />,
                    show: isApproved,
                })}

                {renderElement({
                    path: '/groups',
                    element: <Groups />,
                    show: isPublisher,
                })}

                {renderElement({
                    path: '/tutorials/*',
                    element: <Tutorials />,
                    show: isPublisher,
                })}

                {renderElement({
                    path: '/emergency-plan/*',
                    element: <Emergency />,
                    show: isPublisher,
                })}

                {renderElement({
                    path: '/myAccount/myReport',
                    element: <Report />,
                    show: isPublisher,
                })}

                {renderElement({
                    path: '/myAccount/applications',
                    element: <Applications />,
                    show: isPublisher,
                })}

                {renderElement({
                    path: '/myAccount/myTerritories/*',
                    element: <MyTerritories />,
                    show: isPublisher,
                })}

                {renderElement({
                    path: '/congregation',
                    element: <MyCongregation />,
                    show: isPublisher,
                })}

                {renderElement({
                    path: '/myAccount/myLiterature',
                    element: <MyLiterature />,
                    show: isPublisher,
                })}

                {renderElement({
                    path: '/myAccount/settings',
                    element: <Settings />,
                    show: isPublisher,
                })}

                {renderElement({
                    path: '/counts',
                    element: <Counts />,
                    show: countsAllowed,
                })}

                {renderElement({
                    path: '/elders/groupReport',
                    element: <GroupReport />,
                    show: groupReportAllowed,
                })}

                {renderElement({
                    path: '/elders/ApplicationsAll',
                    element: <ApplicationsAll />,
                    show: applicationsAllowed,
                })}

                {renderElement({
                    path: '/elders/visiting',
                    element: <Visiting />,
                    show: visitingAllowed,
                })}

                {renderElement({
                    path: '/elders/notes',
                    element: <Notes />,
                    show: isElder,
                })}

                {renderElement({
                    path: '/elders/literature/*',
                    element: <Literature />,
                    show: literatureAllowed,
                })}

                {renderElement({
                    path: '/elders/maintenanceReports/*',
                    element: <MaintenanceReports />,
                    show: isElder,
                })}

                {renderElement({
                    path: '/elders/cgReport',
                    element: <CGReport />,
                    show: cgReportAllowed,
                })}

                {renderElement({
                    path: '/elders/cartsPublishers/*',
                    element: <CartPublishers />,
                    show: isElder,
                })}

                {renderElement({
                    path: '/elders/publishers',
                    element: <Publishers />,
                    show: publishersAllowed,
                })}

                {renderElement({
                    path: '/elders/users',
                    element: <Users />,
                    show: usersAllowed,
                })}

                {renderElement({
                    path: '/elders/cgSettings',
                    element: <CGSettings />,
                    show: cgSettingsAllowed,
                })}

                {renderElement({
                    path: '/elders/scheduleSettings/*',
                    element: <ScheduleSettings />,
                    show: scheduleSettingsAllowed,
                })}

                <Route path="*" element={<Navigate to="/schedule" />} />
            </Routes>
        </ContentStyled>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(ContentRight);

const ContentStyled = styled(Box)`
    width: 100%;
    max-width: 1250px;
    min-height: 100vh;
    text-align: left;
    padding: 7px 20px;
    @media ${mediaQueries.m} {
        padding: 10px 7px;
    }

    & h1,
    & h2,
    & h3,
    & h4 {
        font-family: 'DMSans', calibri, tahoma;
        font-weight: 700;
        color: #000000;
    }
    & h1 {
        font-size: 2rem;
    }
    & h2 {
        font-size: 1.7rem;
    }
    & h3 {
        font-size: 1.4rem;
    }
    & h4 {
        font-size: 1.4rem;
    }
`;

const WarningStyled = styled(Box)`
    display: none;
    @media ${mediaQueries.s} {
        display: flex;
    }
`;
const TopButtonStyled = styled.button`
    background: #00b4db;
    &:hover {
        background: #0083b0;
    }
    width: 4rem;
    height: 4rem;
    color: #fff;
    border: 0;
    outline: none;
    cursor: pointer;
    border-radius: 50%;
    font-weight: 800;
    font-size: 1.8rem;
    justify-content: center;
    align-items: center;
    flex: none;
    padding: 1rem;
    position: fixed;
    right: 2rem;
    bottom: 4rem;
    display: ${(props) => (props.visible ? 'flex' : 'none')};
    @media ${mediaQueries.s} {
        bottom: 7rem;
    }
`;
