import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import cloneDeep from 'lodash/cloneDeep';

import { AUTHORIZATION } from 'common/constants';
import { mediaQueries } from 'common/theme';
import useFetch from 'hooks/useFetch';
import * as actionCreators from 'store/actions';
import { genericSort } from 'common/utils';
import Loading from 'components/UI/Loading';
import { Row, Box, MessageBox } from 'components/BasicComponents';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import { confirmModalAsync } from 'components/UI/ConfirmModal';

import Visit from './Visit';

const defaultVisit = {
    date: '',
    congregation: '',
    time: '',
    theme: '',
};

export const processVisits = (data) => {
    const newData = [];
    Object.keys(data).forEach((id) => {
        newData.push({
            date: data[id].date,
            congregation: data[id].congregation,
            publisherID: data[id].publisherID,
            time: data[id].time,
            theme: data[id].theme,
            id,
        });
    });
    return newData;
};

export const processPublishers = (data) => {
    const newData = [];
    Object.keys(data).forEach((id) => {
        if (data[id]?.publisherID) {
            newData.push({
                name: data[id].name,
                surname: data[id].surname,
                suffix: data[id].suffix,
                publisherID: data[id]?.publisherID,
            });
        }
    });
    return newData;
};

const Visiting = (props) => {
    const { t } = useTranslation();

    const [visitingLive, setVisitingLive] = useState(defaultVisit);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [editable, setEditable] = useState('');
    const [visiting, setVisiting] = useState([]);
    const [publishers, setPublishers] = useState([]);

    const token = props.auth.token;
    const publisherID = props.auth.publisherID;
    const canEdit = props.auth.authorization.includes(
        AUTHORIZATION.EDIT_VISITING
    );

    const { data, loading, sendRequest } = useFetch();
    const {
        data: pubData,
        loading: pubLoading,
        sendRequest: sendPubRequest,
    } = useFetch();

    const loadVisiting = useCallback(() => {
        sendPubRequest({
            slug: 'publishers',
            method: 'get',
            token,
            processData: processPublishers,
        });
        sendRequest({
            slug: 'visiting',
            method: 'get',
            token,
            processData: processVisits,
        });
    }, [sendRequest, sendPubRequest, token]);

    const postVisiting = (data) =>
        sendRequest({
            slug: 'visiting',
            method: 'post',
            token,
            data,
            onCompleted: loadVisiting,
        });

    const patchVisiting = (id, data) =>
        sendRequest({
            slug: `visiting/${id}`,
            method: 'patch',
            token,
            data,
            onCompleted: loadVisiting,
        });

    const deleteVisiting = (id, onCompleted) =>
        sendRequest({
            slug: `visiting/${id}`,
            method: 'delete',
            token,
            onCompleted,
        });

    useEffect(() => {
        loadVisiting();
    }, [loadVisiting]);

    useEffect(() => {
        setVisiting(data);
    }, [data]);

    useEffect(() => {
        setPublishers(genericSort(pubData, false, 'text', 'surname'));
    }, [pubData]);

    const changeInputHandler = (event) => {
        setVisitingLive((visitingLive) => ({
            ...visitingLive,
            [event.target.name]: event.target.value,
        }));
    };

    const postHandler = () => {
        if (visitingLive.date === '') {
            setErrorMessage(t('Nevyplnil si dátum'));
            return;
        }
        if (visitingLive.congregation === '') {
            setErrorMessage(t('Nevyplnil si zbor'));
            return;
        }
        setErrorMessage('');
        const data = {
            date: visitingLive.date,
            congregation: visitingLive.congregation,
            time: visitingLive.time,
            theme: visitingLive.theme,
            publisherID,
        };
        postVisiting(data);
        setSuccessMessage(t('Záznam bol úspešne odoslaný'));
        setVisitingLive(defaultVisit);
    };

    const beforeRemoveHandler = async (id) => {
        const confirmResult = await confirmModalAsync({
            props: props,
            title: t('Odstrániť záznam hosťovania'),
            question: t('Naozaj chceš odstrániť tento záznam?'),
        });

        if (confirmResult) {
            const newVisiting = visiting.filter((visit) => visit.id !== id);
            deleteVisiting(id, () => setVisiting(newVisiting));
        }
    };

    const editHandler = (e, id) => {
        const wantedIndex = visiting.findIndex((visit) => visit.id === id);
        const newVisits = [...visiting];
        newVisits[wantedIndex][e.target.name] = e.target.value;
        setVisiting(newVisits);
    };

    const postEditedVisit = (id) => {
        const newVisiting = cloneDeep(visiting);
        const data = newVisiting.find((visit) => visit.id === id);
        delete data.id;
        patchVisiting(id, data);
        setEditable('');
    };

    const visitingList = [];
    genericSort(visiting, false, 'date', 'date').forEach((visiting) => {
        visitingList.push(
            <Visit
                key={visiting.id}
                data={visiting}
                publishers={publishers}
                isMyVisit={visiting.publisherID === publisherID}
                canEdit={canEdit}
                editable={editable}
                edit={(e) => editHandler(e, visiting.id)}
                redButton={() =>
                    editable === visiting.id
                        ? setEditable('')
                        : beforeRemoveHandler(visiting.id)
                }
                blueButton={() =>
                    editable === visiting.id
                        ? postEditedVisit(visiting.id)
                        : setEditable(visiting.id)
                }
            />
        );
    });

    if (loading || pubLoading) {
        return <Loading />;
    }

    return (
        <Box>
            <h2>{t('Pridať záznam')}</h2>
            <VisitCard>
                {`${props.auth.name} ${props.auth.surname}`}

                <Input
                    width="13rem"
                    type="date"
                    name="date"
                    onChange={(e) => {
                        changeInputHandler(e);
                    }}
                    value={visitingLive.date}
                />

                <Input
                    width="13rem"
                    type="text"
                    name="congregation"
                    placeholder={t('Zbor')}
                    maxLength="35"
                    value={visitingLive.congregation}
                    onChange={(e) => {
                        changeInputHandler(e);
                    }}
                />

                <Input
                    width="13rem"
                    type="time"
                    name="time"
                    placeholder={t('Čas')}
                    value={visitingLive.time}
                    onChange={(e) => {
                        changeInputHandler(e);
                    }}
                />

                <Input
                    width="17rem"
                    type="text"
                    name="theme"
                    placeholder={t('Téma')}
                    maxLength="55"
                    value={visitingLive.theme}
                    onChange={(e) => {
                        changeInputHandler(e);
                    }}
                />

                <Button
                    text={t('Odoslať záznam')}
                    type="green"
                    onClick={postHandler}
                    icon="send"
                />
                {!!errorMessage && <MessageBox>{errorMessage}</MessageBox>}
                {!!successMessage && (
                    <MessageBox success>{successMessage}</MessageBox>
                )}
            </VisitCard>

            <h2>{t('Všetky záznamy hosťujúcich rečníkov')}</h2>
            {!!visitingList.length && <Visit isLabel />}
            {visitingList}
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        openModal: (component, componentProps, title) =>
            dispatch(
                actionCreators.openModal(component, componentProps, title)
            ),
        closeModal: () => dispatch(actionCreators.closeModal()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Visiting);

const VisitCard = styled(Row)`
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
    @media ${mediaQueries.m} {
        justify-content: center;
    }
`;
