export const REPORT_DEADLINE_START_DAY = 1;
export const REPORT_DEADLINE_END_DAY = 10;
export const GROUP_REPORT_DEADLINE_END_DAY = 15;

export const REGULAR_PIONEER_HOURS = 50;
export const AUX_PIONEER_HOURS = 30;
export const AUX_PIONEER_HOURS_LOWERED = 15;

export const WEEK_MEETING_DAY = 4;
export const WEEKEND_MEETING_DAY = 7;

export const AUTHORIZATION = {
    APPROVED: 'approved',
    COUNTS_SEND: 'countsSend',
    COUNTS_HISTORY: 'countsHistory',
    EDIT_SCHEDULE: 'editSchedule',
    EDIT_SERVICE_MEETINGS: 'editServiceMeetings',
    EDIT_TALKS: 'editTalks',
    EDIT_ANNOUNCEMENTS: 'editAnnouncements',
    EDIT_CO_SCHEDULE: 'editCOSchedule',
    EDIT_MEMORIAL: 'editMemorial',
    LITERATURE: 'literature',
    TERRITORIES: 'territories',
    EDIT_VISITING: 'editVisiting',
    EDIT_PUBLISHERS: 'editPublishers',
    EDIT_USERS: 'editUsers',
    VIEW_REPORT: 'viewCGreport',
};

export const PRIVILEGES = {
    PUBLISHER: 'publisher',
    CARTS_APPROVED: 'carts',
    AUX_PIONEER: 'auxPioneer',
    PIONEER: 'pioneer',
    MINISTRY_SERVANT: 'ministryServant',
    GROUP_ASSISTANT: 'groupAssistant',
    ELDER: 'elder',
    GROUP_OVERSEER: 'groupOverseer',
    SERVICE_OVERSEER: 'serviceOverseer',
    SECRETARY: 'secretary',
    COORDINATOR: 'coordinator',
};

export const CAT_TYPES = {
    TASKS: 'tasks',
    TASKS_TECHNICAL: 'tasksTechnical',
};

export const TASK_TECHNICAL = {
    ATTENDANT: 'attendant',
    STAGE_MICS: 'stageMics',
    MICS: 'mics',
    MIX: 'mix',
    ZOOM: 'zoom',
    KH_CLEANING: 'khCleaning',
};

export const TASKS = {
    DEMONSTRATION: 'demonstration',
    BIBLE_READING: 'bibleReading',
    PRAYERS: 'prayers',
    READING: 'reading',
    SPEECHES: 'speeches',
    BIBLE_STUDY_CONDUCTOR: 'bibleStudyConductor',
    WEEK_CHAIRMAN: 'weekChairman',
    WEEKEND_CHAIRMAN: 'weekendChairman',
    WT_STUDY_CONDUCTOR: 'wtStudyConductor',
};

export const TERRITORIES_STATES = {
    UNAVAILABLE: 'unavailable', // nedostupny obvod
    FREE: 'free', // dostupny obvod
    PICKED: 'picked', // obvod vybrany zvestovatelom, neschvaleny, upozornenie pre sluzobnika
    IN_PROGRESS: 'inProgress', // obvod sa prepracovava a je schvaleny sluzobnikom
    SUBMITTED: 'submitted', // obvod dokonceny a odovzdany sluzobnikovi na zapis
};

export const DAYS = {
    1: 'Pondelok',
    2: 'Utorok',
    3: 'Streda',
    4: 'Štvrtok',
    5: 'Piatok',
    6: 'Sobota',
    7: 'Nedeľa',
};

export const MONTHS = {
    1: 'Január',
    2: 'Február',
    3: 'Marec',
    4: 'Apríl',
    5: 'Máj',
    6: 'Jún',
    7: 'Júl',
    8: 'August',
    9: 'September',
    10: 'Október',
    11: 'November',
    12: 'December',
};

export const MENU_SECTIONS = {
    ADMINISTRATION: 'administration',
    MY_ACCOUNT: 'myAccount',
    PRIVILEGES: 'privileges',
    ATTENDANT: 'attendant',
    APPOINTED: 'appointed',
    ELDER: 'elder',
    SERVICE_OVERSEER: 'serviceOverseer',
    SECRETARY: 'secretary',
    COORDINATOR: 'coordinator',
};

export const ROUTE_SECTIONS = { MY_ACCOUNT: '/myAccount', ELDER: '/elder' };

export const ROUTES = {
    HOME: '/schedule',

    SCHEDULE: '/schedule',
    SERVICE_MEETINGS: '/serviceMeetings',
    TALKS: '/talks',
    ANNOUNCEMENTS: '/announcements',

    COOPERATION: '/cooperation',
    MEMORIAL: '/memorial',
    TERRITORIES: '/territories',
    GROUPS: '/groups',

    CARTS: 'https://jworg.sk/',

    SCHEDULE_EDIT: '/schedule/edit',
    SERVICE_MEETINGS_EDIT: '/serviceMeetings/edit',
    TALKS_EDIT: '/talks/edit',
    ANNOUNCEMENTS_EDIT: '/announcements/edit',
    COOPERATION_EDIT: '/cooperation/edit',
    MEMORIAL_EDIT: '/memorial/edit',

    MY_REPORT: `${ROUTE_SECTIONS.MY_ACCOUNT}/report`,
    MY_TERRITORIES: `${ROUTE_SECTIONS.MY_ACCOUNT}/territories`,
    MY_LITERATURE: `${ROUTE_SECTIONS.MY_ACCOUNT}/literature`,
    MY_APPLICATIONS: `${ROUTE_SECTIONS.MY_ACCOUNT}/applications`,
    MY_TUTORIALS: '/tutorials',
    MY_SETTINGS: `${ROUTE_SECTIONS.MY_ACCOUNT}/settings`,

    TUTORIALS: '/tutorials',
    GROUP_REPORT: `${ROUTE_SECTIONS.ELDER}/groupReport`,
    APPLICATIONS: `${ROUTE_SECTIONS.ELDER}/applications`,
    VISITING: `${ROUTE_SECTIONS.ELDER}/visiting`,

    COUNTS: '/counts',
    LITERATURE: `${ROUTE_SECTIONS.ELDER}/literature`,
    CG_REPORT: `${ROUTE_SECTIONS.ELDER}/report`,

    PUBLISHERS: `${ROUTE_SECTIONS.ELDER}/publishers`,
    USERS: `${ROUTE_SECTIONS.ELDER}/users`,
    SCHEDULE_SETTINGS: `${ROUTE_SECTIONS.ELDER}/scheduleSettings`,
    CG_SETTINGS: `${ROUTE_SECTIONS.ELDER}/settings`,

    CHANGELOG: '/myAccount/help',
};

export const DATABASE_KEYS = {
    SETTINGS: 'settings',
    USERS: 'users',
    VISITING: 'visiting',
    PUBLISHERS: 'publishers',
    SCHEDULE: 'schedule',
    ANNOUNCEMENTS: 'announcements',
    COUNTS: 'counts',
    TALKS: 'talks',
    SERVICE_MEETINGS: 'serviceMeetings',
    REPORT: 'report',
    APPLICATIONS: 'applications',
    COOPERATION: 'cooperation',
    MEMORIAL: 'memorial',
    TERRITORIES: 'territories',
    SCHEDULE_SETTINGS: 'scheduleSettings',
    DB_VERSION: 'version',
    SCHEDULE_UPDATE: 'scheduleUpdate',
};
