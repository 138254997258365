import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { theme, mediaQueries } from 'common/theme';
import {
    PRIVILEGES,
    REPORT_DEADLINE_START_DAY,
    REPORT_DEADLINE_END_DAY,
    AUTHORIZATION,
} from 'common/constants';
import axios from 'common/axios';
import { getDate } from 'common/utils';
import { Row } from './BasicComponents';
import HamburgerIcon from './UI/HamburgerIcon';
import Logo from './UI/Logo';

import MobileMenu from './MobileMenu';
import UserInfo from './UserInfo';
import HeaderReportWarning from './HeaderReportWarning';

const Header = ({ auth, onClick, install }) => {
    const headerVisibleThreshold = theme.headerVisibleThreshold;
    const headerCompactThreshold = theme.headerCompactThreshold;
    const permanentCompactHeaderWidth = theme.permanentCompactHeaderWidth;
    const date = getDate().day;
    const isApproved =
        auth.token && auth?.authorization && !!auth.authorization.length;
    const isPublisher =
        isApproved && auth?.privileges.includes(PRIVILEGES.PUBLISHER);
    const countsAllowed =
        isPublisher && auth.authorization.includes(AUTHORIZATION.COUNTS_SEND);

    const applyReportWarning = !!(
        date >= REPORT_DEADLINE_START_DAY &&
        date <= REPORT_DEADLINE_END_DAY &&
        isPublisher
    );

    const [reportCompleted, setReportCompleted] = useState(true);
    const [isVisible, setIsVisible] = useState(true);
    const [isCompact, setIsCompact] = useState(false);
    const [cgName, setCgName] = useState(null);

    let lastScrollTop = 0;

    const showHeaderReportWarning = !reportCompleted && applyReportWarning;

    const applyCompactHeader = window.innerWidth > permanentCompactHeaderWidth;

    const fetchReport = useCallback(
        (token, publisherID) => {
            if (applyReportWarning) {
                const queryParams = `?auth=${token}&orderBy="publisherID"&equalTo="${publisherID}"`;
                axios
                    .get(`report.json${queryParams}`)
                    .then((response) => {
                        if (!Object.keys(response.data).length) {
                            setReportCompleted(false);
                        } else {
                            if (
                                Object.keys(response.data).some(
                                    (key) =>
                                        response.data[key].month ===
                                        getDate().monthPrev
                                )
                            ) {
                                setReportCompleted(true);
                            } else {
                                setReportCompleted(false);
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        [applyReportWarning]
    );

    const settingsLoad = useCallback(
        (token) => {
            if (!cgName) {
                axios
                    .get(`settings.json?auth=${token}`)
                    .then((response) => {
                        if (!response.data) {
                            return;
                        }
                        setCgName(response.data.cgName);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        [cgName]
    );

    const handleInstall = () => {
        if (install) {
            install.prompt();
            install.userChoice.then((result) => {
                console.log(result.outcome);
            });
        }
    };

    const updateHeaderVisibility = useCallback(() => {
        const currentPosition = window.scrollY;

        if (applyCompactHeader) {
            if (currentPosition > headerCompactThreshold && !isCompact) {
                setIsCompact(true);
            } else if (currentPosition < headerCompactThreshold && isCompact) {
                setIsCompact(false);
            }
        }

        if (
            currentPosition > lastScrollTop &&
            currentPosition > headerVisibleThreshold
        ) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
        lastScrollTop = currentPosition <= 0 ? 0 : currentPosition;
    }, [
        applyCompactHeader,
        headerCompactThreshold,
        headerVisibleThreshold,
        isCompact,
    ]);

    useEffect(() => {
        window.addEventListener('scroll', updateHeaderVisibility, {
            passive: false,
        });
        return () => {
            window.removeEventListener('scroll', updateHeaderVisibility, {
                passive: false,
            });
        };
    }, [updateHeaderVisibility]);

    useEffect(() => {
        if (isPublisher) {
            fetchReport(auth.token, auth.publisherID);
            settingsLoad(auth.token);
        } else {
            setCgName(null);
        }
    }, [isPublisher, fetchReport, settingsLoad, auth.token, auth.publisherID]);

    useEffect(() => {
        if (isPublisher) {
            fetchReport(auth.token, auth.publisherID);
        }
    }, [
        auth.reportCompleted,
        auth.loginCompleted,
        auth.publisherID,
        isPublisher,
        fetchReport,
        auth.token,
    ]);

    return (
        <>
            <HeaderStyled isCompact={isCompact} isVisible={isVisible}>
                <LogoWrapperStyled>
                    {isApproved && (
                        <HamStyled>
                            <HamburgerIcon onClick={onClick} />
                        </HamStyled>
                    )}
                    <Logo
                        mini={isCompact}
                        cgName={cgName}
                        handleInstall={handleInstall}
                    />
                </LogoWrapperStyled>
                {isApproved && (
                    <MobileMenu
                        isPublisher={isPublisher}
                        countsAllowed={countsAllowed}
                    />
                )}
                <UserInfo />
            </HeaderStyled>
            {showHeaderReportWarning && <HeaderReportWarning />}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(Header);

const HeaderStyled = styled.header`
    background: #e4e4e44d;
    width: 100%;
    height: ${(props) =>
        props.isCompact
            ? props.theme.headerHeight.compact
            : props.theme.headerHeight.desktop};
    display: flex;
    justify-content: space-between;
    padding: ${(props) => (props.isCompact ? '1px 20px' : '5px 20px')};
    position: fixed;
    z-index: ${(props) => props.theme.zIndex.header};
    transition: all 0.3s;
    backdrop-filter: blur(5px);
    transform: translateY(${(props) => (props.isVisible ? '0' : '-100')}%);

    @media ${mediaQueries.m} {
        height: ${(props) => props.theme.headerHeight.compact};
    }

    @media ${mediaQueries.s} {
        bottom: 0;
        height: ${(props) => props.theme.headerHeight.mobile};
        padding-right: 3px;
        padding-left: 15px;
        transform: translateY(0);
    }
    @media ${mediaQueries.xs} {
        padding: 3px 10px;
    }
`;

const LogoWrapperStyled = styled(Row)`
    flex: 0 0;
`;

const HamStyled = styled.div`
    display: none;

    @media ${mediaQueries.m} {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    @media ${mediaQueries.s} {
        float: left;
    }
`;
