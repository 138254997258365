import React, { forwardRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { genericSort } from 'common/utils';
import { formatName } from 'common/utils';
import { Row } from 'components/BasicComponents';
import Input from 'components/UI/Input';

export const ScheduleRow = forwardRef(
    (
        {
            text,
            data,
            isMyTask,
            isLabel,
            isActual,
            isEditable,
            changeHandler,
            inputType,
            surnameFellows,
            IDs,
        },
        ref
    ) => {
        const duplicateNum = IDs?.filter(
            (id) => id === data?.publisherID
        ).length;

        const { t } = useTranslation();
        const background = () => {
            if ((!data?.background || isEditable) && !isLabel) {
                return 'transparent';
            } else {
                return isLabel ? data.background : `${data?.background}19`;
            }
        };

        const monthNames = {
            1: t('januára'),
            2: t('februára'),
            3: t('marca'),
            4: t('apríla'),
            5: t('mája'),
            6: t('júna'),
            7: t('júla'),
            8: t('augusta'),
            9: t('septembra'),
            10: t('októbra'),
            11: t('novembra'),
            12: t('decembra'),
        };

        const duplicationsColors = {
            2: { bg: 'rgba(83, 198, 233, 0.9)', cl: '#ffffff' },
            3: { bg: 'rgba(233, 186, 83, 0.9)', cl: '#ffffff' },
            4: { bg: 'rgba(250, 116, 54, 0.9)', cl: '#ffffff' },
            5: { bg: 'rgba(247, 16, 16, 0.9)', cl: '#ffffff' },
        };

        const getDuplicationColors = (num, isColor) => {
            const propertyName = isColor ? 'cl' : 'bg';
            if (num < 5) {
                return duplicationsColors[duplicateNum]?.[propertyName];
            } else {
                return duplicationsColors['5']?.[propertyName];
            }
        };

        const options = !data?.options
            ? []
            : genericSort(
                  genericSort(data?.options, false, 'text', 'name'),
                  false,
                  'text',
                  'surname'
              ).map((option, i) => {
                  const formattedName = formatName(option, surnameFellows);

                  return (
                      <option
                          key={`${option.publisherID}_${i}`}
                          value={option.publisherID}
                      >
                          {formattedName || null}
                      </option>
                  );
              });

        let renderedText = isLabel ? text : data.value;

        const realInputType = inputType || data?.type || 'select';

        if (
            realInputType === 'date' &&
            !isLabel &&
            text?.length &&
            !isNaN(+text.slice(5, 7))
        ) {
            const month = monthNames[+text.slice(5, 7)];
            const day = +text.slice(8, 10);
            renderedText = `${day}. ${month}`;
        }

        const editableContent =
            realInputType !== 'select' ? (
                <Input
                    ref={ref}
                    key={data.id}
                    data={data}
                    name={data?.name || data.id}
                    type={realInputType}
                    placeholder={data?.placeholder}
                    value={data?.value}
                    onChange={(e) => changeHandler(e)}
                    height="27px"
                    width="100%"
                    margin="0"
                    uppercase={data?.uppercase}
                    maxLength="25"
                    noBorder
                />
            ) : (
                <>
                    <Select
                        key={data.id}
                        duplicateNum={duplicateNum}
                        fontWeight={isMyTask ? '800' : data?.fontWeight}
                        uppercase={data?.uppercase}
                        fontSize={data?.fontSize}
                        name={data.id}
                        value={data?.publisherID}
                        onChange={(e) => changeHandler(e)}
                        maxLength="18"
                        isEditable={isEditable}
                    >
                        {options}
                    </Select>
                    {duplicateNum > 1 && (
                        <DuplicationStyled
                            background={getDuplicationColors(duplicateNum)}
                            color={getDuplicationColors(duplicateNum, true)}
                            class="duplicationNum"
                        >
                            {duplicateNum}
                        </DuplicationStyled>
                    )}
                </>
            );

        const renderedColor =
            isActual && realInputType === 'date'
                ? 'rgb(15, 115, 4)'
                : data?.dataColor;

        return (
            <RowStyled
                background={background}
                fontWeight={isMyTask ? '800' : data?.fontWeight}
                uppercase={data?.uppercase}
                color={
                    isMyTask ? '#fff' : isLabel ? data?.color : renderedColor
                }
                fontSize={
                    data?.fontSize ? data?.fontSize : isLabel ? 1.1 : null
                }
                borderColor={
                    isActual && data?.borderColor
                        ? 'rgba(4, 153, 29, 0.7)'
                        : data?.borderColor
                }
                isLabel={isLabel}
                isMyTask={isMyTask}
                isEditable={isEditable}
                withoutRadius={realInputType === 'date'}
            >
                {isEditable ? editableContent : renderedText}
            </RowStyled>
        );
    }
);

const myTaskHighlight = keyframes`
  0% {
    background: rgb(199, 34, 70);
  }
  50% {
    background: rgb(131, 17, 46);
    color: #fff;
  }
  100% {
    background: rgb(199, 34, 70);
  }
`;

const RowStyled = styled(Row)`
    width: 100%;
    background: ${(props) => props.background};
    color: ${(props) => props?.color || 'rgb(0, 0, 0)'};
    height: 27px;
    text-align: ${(props) => (props.isLabel ? 'right' : 'center')};
    margin: 0.5px 0;
    justify-content: ${(props) => (props.isLabel ? 'flex-end' : 'center')};
    align-items: center;
    padding: ${(props) =>
        props.isLabel
            ? '0px 5px 0px 10px'
            : props.isEditable
            ? '0'
            : '0px 7px'};
    font-family: 'DMSans', calibri, tahoma;
    font-weight: ${(props) => props?.fontWeight || '400'};
    font-size: ${(props) =>
        props.fontSize ? `${props.fontSize}rem` : '1.3rem'};
    border-radius: ${(props) => (props.withoutRadius ? '0 !important' : '')};
    border-bottom: ${(props) =>
        props.borderColor ? `1px solid ${props.borderColor}` : '0'};
    text-transform: ${(props) => (props.uppercase ? 'uppercase' : 'none')};
    animation-name: ${(props) => (props.isMyTask ? myTaskHighlight : 'none')};
    animation-duration: 0.7s;
    animation-timing-function: ease-in;
    animation-iteration-count: infinite;
    overflow: hidden;
    position: relative;
    &:first-of-type {
        align-self: ${(props) => (props.isLabel ? 'flex-end' : 'none')};
    }
`;

const DuplicationStyled = styled.span`
    background: ${(p) => p.background};
    min-width: 1.8rem;
    height: 1.8rem;
    position: absolute;
    right: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.2rem;
    border-radius: 50%;
    color: ${(p) => p?.color};
    font-weight: 700;
`;

const Select = styled.select`
    background: rgb(212, 241, 212);
    color: rgb(23, 93, 26);
    height: 27px;
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 0;
    padding-right: ${(props) => (props.isEditable ? '20px' : '')};
    outline: none;
    font-weight: ${(props) => props?.fontWeight || '400'};
    font-size: ${(props) =>
        props.fontSize ? `${props.fontSize}rem` : '1.2rem'};
    border-bottom: ${(props) =>
        props.borderColor ? `1px solid ${props.borderColor}` : '0'};
    text-transform: ${(props) => (props.uppercase ? 'uppercase' : 'none')};
    position: relative;
`;
