import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';

import { mediaQueries } from 'common/theme';
import { Row } from './BasicComponents';
import { Portal } from './Portal';
import CloseButton from './UI/CloseButton';

const HeaderReportWarning = () => {
    const { t } = useTranslation();
    const [isOpened, setIsOpened] = useState(true);

    const handleClose = () => {
        setIsOpened(false);
    };

    return isOpened ? (
        <Portal type="warning-root">
            <ReportWarning>
                <WarningText to="/myAccount/myReport">
                    {t('Nemáš odovzdanú správu')}
                </WarningText>
                <CloseButton onClick={handleClose} top="8px" />
            </ReportWarning>
        </Portal>
    ) : null;
};

export default HeaderReportWarning;

const warn = keyframes`
  50% {
    transform: scale(1.1);
  }
`;

const ReportWarning = styled(Row)`
    background: linear-gradient(90deg, #eb3349, #f45c43);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: ${(props) => props.theme.reportWarningHeight};
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: ${(props) => props.theme.zIndex.reportWarning};
    &:hover {
        background: #eb3349;
    }
    @media ${mediaQueries.s} {
        top: 0;
    }
`;
const WarningText = styled(NavLink)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    font-weight: 800;
    text-transform: uppercase;
    color: #fff;
    position: relative;
    animation: ${warn} 1s ease-out infinite;
    &:hover {
        color: #fff;
    }
`;
