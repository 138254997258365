import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Box, Row, MessageBox } from 'components/BasicComponents';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';
import { mediaQueries } from 'common/theme';

const LoginContent = ({
    signIn,
    user,
    changeHandler,
    postAuth,
    passwordReset,
    passwordResetF,
    beforeSignInToggler,
    message,
    errorMessage,
    regSuccessMessage,
}) => {
    const { t } = useTranslation();

    return (
        <WrapperStyled
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <HeadingStyled>
                {signIn ? t('Prihlásenie') : t('Registrácia')}
            </HeadingStyled>

            <WrapperStyled>
                <InputWrapperStyled>
                    <Input
                        name="email"
                        type="email"
                        autoComplete={
                            signIn ? 'current-password' : 'new-password'
                        }
                        placeholder={
                            signIn
                                ? t('Prihlasovací email')
                                : t('Emailová adresa')
                        }
                        maxLength="35"
                        value={user.email}
                        onChange={(e) => changeHandler(e)}
                        width="100%"
                        height="35px"
                    />
                    <Input
                        name="password"
                        type="password"
                        minLength="6"
                        maxLength="15"
                        autoComplete={
                            signIn ? 'current-password' : 'new-password'
                        }
                        placeholder={
                            signIn ? t('Hesielko') : t('Nové hesielko')
                        }
                        value={user.password}
                        onChange={(e) => changeHandler(e)}
                        width="100%"
                        height="35px"
                    />
                    {!signIn && (
                        <>
                            <Input
                                type="text"
                                name="name"
                                placeholder={t('Meno')}
                                maxLength="15"
                                value={user.name}
                                onChange={(e) => changeHandler(e)}
                                width="100%"
                                height="35px"
                            />

                            <Input
                                type="text"
                                name="surname"
                                placeholder={t('Priezvisko')}
                                maxLength="20"
                                value={user.surname}
                                onChange={(e) => changeHandler(e)}
                                width="100%"
                                height="35px"
                            />
                        </>
                    )}
                </InputWrapperStyled>
                {!!message && <MessageBox>{message}</MessageBox>}
                {!!errorMessage && <MessageBox>{errorMessage}</MessageBox>}
                {!!regSuccessMessage && (
                    <MessageBox success>{regSuccessMessage}</MessageBox>
                )}
                <Button
                    text={signIn ? t('Prihlásiť sa') : t('Vytvoriť účet')}
                    type="green"
                    onClick={
                        signIn
                            ? (e) => postAuth(e, 'signIn')
                            : (e) => postAuth(e, 'signUp')
                    }
                    icon={signIn ? 'login' : 'plus'}
                    py="1rem"
                />
            </WrapperStyled>

            <ButtonsWrapperStyled>
                {signIn && !passwordReset && (
                    <Button
                        text={t('Zabudol som heslo')}
                        type="orange"
                        onClick={passwordResetF}
                        icon="unlock"
                        py="1rem"
                    />
                )}
                <Button
                    text={
                        signIn
                            ? t('Vytvoriť nový účet')
                            : t('Už mám vytvorený účet')
                    }
                    type="blue"
                    onClick={beforeSignInToggler}
                    icon={signIn ? 'plus' : 'user'}
                    py="1rem"
                />
            </ButtonsWrapperStyled>
        </WrapperStyled>
    );
};

export default LoginContent;

const HeadingStyled = styled(Box)`
    font-size: 1.5rem;
    font-weight: 700;
`;
const WrapperStyled = styled(Box)`
    align-items: center;
    width: 100%;
`;
const InputWrapperStyled = styled(Box)`
    width: 100%;
    margin-bottom: 0.8rem;
`;
const ButtonsWrapperStyled = styled(Row)`
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;
    flex-wrap: wrap;
    & button {
        margin: auto;
    }
    @media ${mediaQueries.menuM} {
        flex-direction: column;
    }
`;
