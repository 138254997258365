import React from 'react';
import styled from 'styled-components';

import { Box } from 'components/BasicComponents';

export const ScheduleColumn = ({ children, isActual, isLabel }) => (
    <Column isActual={isActual} isLabel={isLabel}>
        {children}
    </Column>
);

const Column = styled(Box)`
    background: ${(props) =>
        props.isActual ? props.theme.colors.schedule.active : 'transparent'};
    align-items: stretch;
    margin: 0;
    min-width: 100px;
    margin-right: ${(props) => (props.isLabel ? '0px' : '1px')};
    flex: none;
    &:first-of-type div {
        border-radius: ${(props) =>
            props.isLabel
                ? `${props.theme.radius.xs} 0 0
      ${props.theme.radius.xs}`
                : ''};
    }
    &:last-of-type div {
        border-radius: ${(props) =>
            !props.isLabel
                ? `0 ${props.theme.radius.xs}
      ${props.theme.radius.xs} 0`
                : ''};
    }
`;
