import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
    genericSort,
    formatName,
    createSurnameFellowsArray,
} from 'common/utils';
import { Box } from 'components/BasicComponents';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';

const ScheduleNewColumn = ({ change, data, options, post, publishers }) => {
    const { t } = useTranslation();

    const rowsList = [];

    const surnameFellows = createSurnameFellowsArray(publishers);

    const IDs = [];

    data.forEach((row) => {
        if (row?.publisherID) {
            IDs.push(row?.publisherID);
        }

        if (row?.type === 'select' || !row?.type) {
            rowsList.push(
                <Input
                    key={row.id}
                    type={'select'}
                    value={row?.publisherID}
                    onChange={(e) => change(e, row.id)}
                    duplicateNum={
                        IDs.filter((id) => id === row?.publisherID).length
                    }
                    data={row}
                    borderRadius="0.8rem"
                    height="27px"
                    width="100%"
                    margin="0.5px 0"
                    padding="0 15px 0 0"
                    noBorder
                >
                    {options[row.optionsCategory]?.length &&
                        genericSort(
                            genericSort(
                                options[row.optionsCategory],
                                false,
                                'text',
                                'name'
                            ),
                            false,
                            'text',
                            'surname'
                        ).map((option, i) => {
                            const formattedName = formatName(
                                option,
                                surnameFellows
                            );

                            return (
                                <option key={i} value={option?.publisherID}>
                                    {formattedName}
                                </option>
                            );
                        })}
                </Input>
            );
        } else {
            rowsList.push(
                <Input
                    key={row.id}
                    placeholder={row.placeholder}
                    type={row?.type}
                    maxLength={row.maxlength}
                    value={row.value}
                    onChange={(e) => change(e, row.id)}
                    data={row}
                    borderRadius="0.8rem"
                    height="27px"
                    width="100%"
                    margin="0.5px 0"
                    noBorder
                />
            );
        }
    });

    return (
        !!rowsList.length && (
            <ColumnStyled>
                {rowsList}
                <Button
                    text={t('Pridať stĺpec')}
                    type="green"
                    onClick={post}
                    icon="plus"
                    my="5px"
                />
            </ColumnStyled>
        )
    );
};

export default ScheduleNewColumn;

const ColumnStyled = styled(Box)`
    align-items: center;
    margin: 0px 5px;
    min-width: fit-content;
`;
