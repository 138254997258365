import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { mediaQueries } from 'common/theme';
import { formatISODate } from 'common/utils';
import { Icons } from 'common/Icons';
import { Row } from 'components/BasicComponents';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';

const Visit = ({
    data,
    publishers,
    redButton,
    blueButton,
    isLabel,
    editable,
    edit,
    canEdit,
    isMyVisit,
    isInSchedule,
}) => {
    const { t } = useTranslation();
    const { publisherID, id, date, congregation, time, theme } = data || {};

    const visitPublisher =
        publishers && publisherID
            ? publishers.find(
                  (publisher) => publisher.publisherID === publisherID
              )
            : null;

    const publisherName = visitPublisher
        ? `${visitPublisher.surname} ${visitPublisher.name} ${
              visitPublisher.suffix || ''
          }`
        : null;

    if (isLabel) {
        return (
            <VisitStyled isLabel>
                <ColumnStyled className="date">{t('Dátum')}</ColumnStyled>
                <ColumnStyled className="name">{t('Meno')}</ColumnStyled>
                <ColumnStyled className="congregation">
                    {t('Zbor')}
                </ColumnStyled>
                {!isInSchedule && (
                    <>
                        <ColumnStyled className="time">{t('Čas')}</ColumnStyled>
                        <ColumnStyled className="theme">
                            {t('Osnova / námet')}
                        </ColumnStyled>
                    </>
                )}
            </VisitStyled>
        );
    }

    const isEditable = editable === id;

    const formattedDate = formatISODate(date);

    return (
        <VisitStyled>
            <ColumnStyled className="date">
                <i className={Icons.calendar} />
                {isEditable ? (
                    <Input
                        type="date"
                        name="date"
                        value={date}
                        onChange={edit}
                        margin="0"
                        height="3rem"
                    />
                ) : (
                    formattedDate
                )}
            </ColumnStyled>
            <ColumnStyled className="name">
                <i className={Icons.speaker} />
                {publisherName}
            </ColumnStyled>
            <ColumnStyled className="congregation">
                <i className={Icons.mapmarker} />
                {isEditable ? (
                    <Input
                        type="text"
                        name="congregation"
                        maxLength="35"
                        placeholder={t('Zbor')}
                        value={congregation}
                        onChange={edit}
                        margin="0"
                        height="3rem"
                    />
                ) : (
                    congregation
                )}
            </ColumnStyled>
            {!isInSchedule && (
                <>
                    <ColumnStyled className="time">
                        <i className={Icons.time} />
                        {isEditable ? (
                            <Input
                                type="time"
                                name="time"
                                placeholder={t('Čas')}
                                value={time}
                                onChange={edit}
                                margin="0"
                                height="3rem"
                            />
                        ) : (
                            time
                        )}
                    </ColumnStyled>
                    <ColumnStyled className="theme">
                        {isEditable ? (
                            <Input
                                type="text"
                                name="theme"
                                maxLength="55"
                                placeholder={t('Námet')}
                                value={theme}
                                onChange={edit}
                                margin="0"
                                height="3rem"
                            />
                        ) : (
                            theme
                        )}
                    </ColumnStyled>
                </>
            )}
            {isMyVisit || canEdit ? (
                <ButtonsWrapperStyled>
                    <Button
                        type="blue"
                        onClick={blueButton}
                        icon={isEditable ? 'save' : 'edit'}
                        transparent
                    />
                    <Button
                        type="red"
                        onClick={redButton}
                        icon={isEditable ? 'cancel' : 'delete'}
                        transparent
                    />
                </ButtonsWrapperStyled>
            ) : null}
        </VisitStyled>
    );
};

export default Visit;

const VisitStyled = styled(Row)`
    background: ${(props) => (props.isLabel ? props.theme.colors.label : '')};
    color: ${(props) => (props.isLabel ? '#fff' : '#000')};
    border-radius: ${(props) => (props.isLabel ? '0.8rem' : '')};
    border-bottom: 1px solid ${(props) => props.theme.colors.black0};
    padding: 0 5px;
    flex-wrap: wrap;
    font-size: 1.4rem;
    &:hover {
        background: ${(props) => (props.isLabel ? '' : 'rgba(0, 0, 0, 0.04)')};
    }
    & button {
        margin: 0;
        border-radius: 0;
        font-weight: 600;
    }
    & i {
        margin-right: 0.7rem;
    }
    @media ${mediaQueries.s} {
        justify-content: center;
        align-items: center;
    }
`;

const ButtonsWrapperStyled = styled(Row)`
    @media ${mediaQueries.m} {
        width: 100%;
        justify-content: center;
    }
`;

const ColumnStyled = styled(Row)`
    align-items: center;
    padding: 0.5rem;
    @media ${mediaQueries.s} {
        justify-content: center;
    }
    &.name {
        min-width: 16rem;
    }
    &.date {
        min-width: 11.5rem;
        font-weight: 600;
    }
    &.time {
        min-width: 7rem;
    }
    &.theme {
        min-width: 15rem;
        font-size: 1rem;
    }
    &.congregation {
        min-width: 13rem;
        font-size: 1.1rem;
    }
`;
