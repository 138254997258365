import React, { useState, useEffect, createContext } from 'react';
import { connect } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import styled from 'styled-components';

import { ThemeProvider } from 'components/ThemeProvider';
import * as actionCreators from 'store/actions/index';
import Header from 'components/Header';
import Content from 'components/Content';
import SideDrawer from 'components/UI/SideDrawer';
import { Box } from 'components/BasicComponents';
import { Modal } from 'components/UI/Modal';
// import Flash from 'components/UI/Flash';

import LoginScreen from './LoginScreen';

export const ThemeContext = createContext(null);

const App = ({ refreshAuth }) => {
    const [openedSideDrawer, setOpenedSideDrawer] = useState(false);
    const [darkMode, setDarkMode] = useState(false);
    useEffect(() => {
        refreshAuth();
    }, [refreshAuth]);

    let installPrompt;

    // window.addEventListener('beforeinstallprompt', e => {
    //   e.preventDefault();
    //   installPrompt = e;
    //   return false;
    // });

    const sideHandler = () => {
        setOpenedSideDrawer((openedSideDrawer) => !openedSideDrawer);
    };

    return (
        <HashRouter>
            <ThemeProvider>
                <ThemeContext.Provider value={{ darkMode, setDarkMode }}>
                    <AppStyled>
                        {/* <Flash /> */}
                        <Modal />
                        <LoginScreen />
                        <SideDrawer
                            onClick={sideHandler}
                            openedSideDrawer={openedSideDrawer}
                        />
                        <Header onClick={sideHandler} install={installPrompt} />
                        <Content />
                    </AppStyled>
                </ThemeContext.Provider>
            </ThemeProvider>
        </HashRouter>
    );
};

const AppStyled = styled(Box)`
    width: 100%;
    height: 100%;
    background: '#ffffff';
`;

const mapDispatchToProps = (dispatch) => {
    return {
        refreshAuth: () => dispatch(actionCreators.refreshAuth()),
    };
};

export default connect(null, mapDispatchToProps)(App);
